.ui.inverted.primary.button:focus {
  color: var(--primary);
  background-color: transparent;
}

.ui.loading.button:after {
  border-color: var(--primary) transparent transparent;
}

.ui.button + .ui.button {
  margin-left: 0px;
}

.ui.primary.button {
  background: linear-gradient(#d7319e, #6331f2);
  border-radius: var(--button-border-radius);
  font-family: var(--font-poppins);
  text-transform: initial;
  font-size: 21px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 250px;
}
