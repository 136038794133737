.nft-start-game-button,
.nft-start-game-button:hover,
.nft-start-game-button:active,
.nft-start-game-button:focus {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(#d7b731, #f25f31);
    border-radius: var(--button-border-radius);
    color: #232123;
    justify-content: center;
    height: var(--button-height);
    font-size: var(--font-size-medium);
    font-family: var(--font-monospace);
    text-transform: initial;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    width: var(--button-width);
    margin-left: 10px;
}

@media (max-width: 768px) {
    .nft-start-game-button,
    .nft-start-game-button:hover,
    .nft-start-game-button:active,
    .nft-start-game-button:focus {
        position: absolute;
        top: 0px;
        right: 5px;
        margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
        margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
        width: 80px;
        height: 40px;
        font-size: 10px;
    }
}