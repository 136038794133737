.PageCustomHeader .dcl.navbar .ui.container {
  align-items: baseline;
}

.dcl.navbar .ui.container {
  align-items: baseline !important;
}

.dcl.navbar .ui.menu .item.item {
  font-size: 20px;
  margin-left: 80px;
}

.PageCustomHeader .dcl.navbar .ui.menu.secondary {
  transform: translateY(14px);
}

.dcl.navbar .ui.menu.secondary {
  transform: translateY(14px) !important;
}

.dcl.navbar-logo.navbar-logo {
  margin-top: 4px;
}

.navbar-account .nft-start-game-button{
  font-family: 'PT Mono', sans-serif;
  color: black;
  font-size: 16.8px;
  line-height: 18px;
  font-weight: 700px;
}

.dcl .navbar-account .item .sign-in-button a{
  font-family: 'PT Mono', sans-serif;
  color: #fff;
  font-size: 16.8px;
  line-height: 18px;
  font-weight: 700px;
}

.dcl.navbar .ui.menu .item .bell {
  color: var(--text);
}

.dcl.navbar .ui.menu .item.active .bell {
  color: var(--primary);
}

.dcl.navbar .ui.menu .item .bell.pending:after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--primary);
  position: absolute;
  border-radius: 100%;
  top: -5px;
  right: -5px;
}

.dcl.account-wrapper .dcl.mana {
  flex: none;
}

.dcl.navbar .ui.menu .item.sign-in-button {
  transform: translateY(-8px);
}

.dcl.navbar .ui.menu .item.sign-in-button,
.dcl.navbar .ui.menu .item.sign-in-button:hover,
.dcl.navbar .ui.menu .item.sign-in-button:active,
.dcl.navbar .ui.menu .item.sign-in-button:focus {
  background: linear-gradient(#d7319e, #6331f2);
  border-radius: var(--button-border-radius);
  color: #ffff;
  justify-content: center;
  height: var(--button-height);
  font-size: var(--font-size-medium);
  text-transform: initial;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  width: var(--button-width);
}

.dcl.navbar .ui.menu .item.sign-in-button:hover {
  transform: translateY(-10px);
}

.dcl.navbar .ui.menu .item.start-game-button {
  transform: translateY(-8px);
  margin-left: 10px;
}

.dcl.navbar .ui.menu .item.start-game-button,
.dcl.navbar .ui.menu .item.start-game-button:hover,
.dcl.navbar .ui.menu .item.start-game-button:active,
.dcl.navbar .ui.menu .item.start-game-button:focus {
  background: linear-gradient(#d7b731, #f25f31);
  border-radius: var(--button-border-radius);
  color: #232123;
  justify-content: center;
  height: var(--button-height);
  font-size: var(--font-size-medium);
  text-transform: initial;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  width: var(--button-width);
  margin-left: 10px;
}

.dcl.navbar .ui.menu .item.start-game-button:hover {
  transform: translateY(-10px);
}

.dcl.navbar .ui.menu.secondary {
  transform: translateY(28px);
}

.dcl.navbar .TextFilter .text-input {
  background-color: #323132;
  border-radius: 17px;
  padding-left: 5px;
  padding-right: 70px;
}

.dcl.navbar .TextFilter .text-input input {
  font-size: 15px;
}

@media (max-width: 768px) {
  .PageCustomHeader .dcl.navbar .ui.container {
    flex-direction: column;
  }

  .navbar-account .nft-start-game-button{
    font-family: 'PT Mono', sans-serif;
    color: black;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .dcl.navbar .ui.container {
    display: block !important;
    align-items: baseline !important;
  }

  .kryptomon.logo {
    margin-right: 20px !important;
    width: 82px !important;
    height: 32px !important;
    background-repeat: no-repeat;
  }

  .dcl.navbar .navbar-mobile-menu .ui.header {
    margin-left: 80px !important;
  }

  .dcl.navbar .mobile-menu {
    z-index: 100000;
    margin-top: -20px;
    padding-left: 70px !important;
  }

  .dcl.navbar .mobile-menu .item {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
  }

  .marketplace-sub-logo {
    display: block !important;
    width: 58px !important;
    height: 28px !important;
  }

  .dcl.navbar .ui.menu .item.item {
    font-size: 20px;
    margin-left: 40px;
    font-family: var(--font-poppins);
    text-transform: initial;
    font-size: 21px;
    font-weight: 900;
  }

  .dcl.navbar .ui.menu .item.sign-in-button,
  .dcl.navbar .ui.menu .item.sign-in-button:hover,
  .dcl.navbar .ui.menu .item.sign-in-button:active,
  .dcl.navbar .ui.menu .item.sign-in-button:focus {
    height: var(--button-height);
    font-size: 12px;
    width: 75px;
    height: 30px;
    margin-left: 0px;
  }

  .dcl.navbar .ui.menu .item.start-game-button,
  .dcl.navbar .ui.menu .item.start-game-button:hover,
  .dcl.navbar .ui.menu .item.start-game-button:active,
  .dcl.navbar .ui.menu .item.start-game-button:focus {
    height: var(--button-height);
    font-size: 12px !important;
    width: 80px;
    height: 30px;
    margin-left: 10px;
  }

  .dcl.navbar-account {
    /* margin-top: 17px; */
    /* position: absolute; */
    width: 100%;
    right: 5px;
    top: calc(var(--navbar-margin-bottom) / 2) !important;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .dcl.navbar-account > div {
    justify-content: space-between !important;
  }

  .dcl.navbar-account .ui.secondary.menu {
    flex-direction: column;
  }

  .dcl.navbar .ui.menu.secondary {
    margin-top: 0px !important;
  }

  .dcl.navbar .ui.menu .item.start-game-button {
    width: 75px;
    height: 30px;
    margin-left: 0px !important;
    margin-top: 5px;
  }

  .PageCustomHeader .dcl.navbar .ui.menu.secondary {
    transform: translateY(28px);
  }

  .dcl.navbar .ui.menu.secondary {
    margin-top: 0px !important;
  }

  .dcl.tabs-left {
    background-color: #000;
  }
}

@media (min-width: 768px) {
  .PageCustomHeader .dcl.navbar .dcl.navbar-account {
    margin-top: 25px;
  }
}