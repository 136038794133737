.breeding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 272px;
}

.breeding-box {
  background-color: #323132;
  padding: 12px;
  padding-top: 18px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1201px) {
  .top-element {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .breeding-container {
    width: 100%;
  }
  .top-element {
    width: 100%;
  }
}

.cooldown-time {
  font-size: 10px;
  color: #ddd;
  margin: 0;
}

.set-price-text {
  font-size: 12px;
  color: rgb(180, 42, 120);
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}

.set-price-text:hover {
  text-shadow: 0 0 3px #fff9, 0 0 5px #00f4;
}

.margin-vertical {
  margin: 10px 0;
}
