.Title {
  margin-top: 35px;
}

.Title .left .ui.large.header {
  margin-bottom: 0;
  margin-right: 10px;
}

.Title .Column.left .ui.header > .text {
  display: flex;
}

.Title .Badge {
  margin-left: 16px;
  margin-top: 6px;
}

@media (max-width: 768px) {
  .Title .left {
    margin-right: 0px;
    margin-bottom: 24px;
  }
}
