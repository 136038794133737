.VendorStrip.Menu {
  display: flex;
  width: inherit;
  margin-left: 0;
}

.VendorStrip.Menu .MenuItem {
  padding: 5px 12px;
  margin-right: 15px;
  background-color: var(--card);
  display: flex;
  align-items: center;
}

.VendorStrip.Menu .MenuItem:not(.active):hover {
  font-weight: normal;
}

.VendorStrip.Menu .MenuItem img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.VendorStrip.Menu .MenuItem .content {
  font-size: 12px;
}
