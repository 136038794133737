.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.orderitemdetail-seller-container {
  background-image: url(../../../images/items/crafting-background.svg);
  background-size: cover;
  height: auto;
  padding: 0px 25px;
  border-radius: 10px;
  border: 1px solid #29292A;
  max-height: 500px;
  overflow: auto;
}

.lootbox-detail .lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

.connect-center {
  text-align: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}