.ui.cards > .ui.card.link,
.ui.card.link.LootboxCard {
  box-shadow: var(--shadow-2);
  transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
  align-self: auto;
}

.LootboxCard .card-image-text.candy {
  align-items: flex-end;
}

.LootboxCard .product-type-price {
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: fit-content;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #000000;
}

.LootboxCard .product-type-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  background: #6331f2;
  border-radius: 50px;
  color: #ffffff;
}

.LootboxCard .product-type-price-candy {
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.LootboxCard .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.LootboxCard .product-info-number-card {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LootboxCard .product-description-container {
  height: 78px;
  min-height: 78px;
  justify-content: center;
}

.LootboxCard .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.LootboxCard .product-description-left-item {
  color: #000000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.LootboxCard .product-description-right-nft {
  position: absolute;
  right: 20px;
  bottom: 5px;
  /* background-color: #6331f2; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex; */
}

.LootboxCard .product-description-right-nft > img {
  width: 31px;
  height: 36px;
}

.LootboxCard .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .ui.cards > .ui.card.link,
  .ui.card.link.LootboxCard {
    width: 100%;
  }
}
