.kryptomon.breeding-modal .ui.grid>.row {
  padding: 0 !important;
}

.kryptomon.breeding-modal .kryptomon-box.left {
  display: flex;
  padding-right: 82px;
}

.kryptomon.breeding-modal .kryptomon-box.left > div {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.kryptomon.breeding-modal .kryptomon-box.left .NFTImage {
  width: 150px;
}

.kryptomon.breeding-modal .kryptomon-box.left .detail {
  flex-direction: column;
}

.kryptomon.breeding-modal .kryptomon-box.right {
  display: flex;
  padding-left: 82px;
}

.kryptomon.breeding-modal .kryptomon-box.right > div {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .kryptomon.breeding-modal .ui.grid>.row {
    flex-direction: column;
  }

  .ui.modal > .content {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ui.modal > .content {
    width: 100% !important;
    margin-left: 0px !important;
  }
}