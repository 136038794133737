.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  width: 26px;
  height: 26px;
  opacity: 1;
}

.ui.radio.checkbox.checked input.hidden + label:after {
  top: 0px;
  left: -1px;
  width: 29px;
  height: 32px;
}
