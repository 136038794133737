.HomePage .dcl.tabs {
  text-align: center;
  font-family: var(--font-poppins);
  border: 0px;
  margin-top: 25px;
}

.HomePage .dcl.page {
  min-height: auto;
  width: 100%;
}

.dcl.navbar .ui.container {
  align-items: center !important;
}

.homepage-horizontal-nft-scroll{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  min-height: 300px;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #101010;
}
.homepage-horizontal-nft-scroll::-webkit-scrollbar-track { 
  background-color: #313031; 
  border-radius: 8px; 
}

@media only screen and (min-width: 768px) {
  .HomePage .PageCustomHeader .dcl.tabs {
    display: none;
  }
}

.dcl.hero.centered {
  align-items: center;
  border: 0px;
}

.HomePageHero.dcl.hero {
  height: var(--page-header-height);
  padding: 64px 64px 30px;
  margin-bottom: 0px;
}

.HomePageHero {
  display: flex;
  align-items: center;
}

.HomePageHero .hero-title-text {
  font-family: var(--font-family);
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 15;
  font-size: 70px;
  color: #e7e3d7;
  letter-spacing: -1.56px;
  line-height: 65px;
  width: 648px;
  margin-top: -332px;
}

.dcl.header-menu .ui.header {
  margin-bottom: 0px;
  font-family: var(--font-poppins) !important;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1064px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.container {
    width: 953px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.dcl.hero .hero-content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.HomePageHero .hero-actions .button-secondary {
  background-image: linear-gradient(180deg, #d7b731 0%, #f25f31 100%) !important;
  color: #232123 !important;
  margin-top: 100px;
  text-transform: uppercase !important;
}

.hero-logo {
  background-image: url(../../images/kryptomon-logo.svg);
  position: absolute;
  height: 140px;
  width: 343px;
  margin-top: 69px;
  background-size: 362px 122px;
}

.hero-market {
  background-image: url(../../images/kryptomon-market.svg);
  position: absolute;
  height: 120px;
  width: 301px;
  margin-top: 340px;
  background-size: 300px 120px;
  z-index: 19;
}

.hero-fade {
  background-image: url(../../images/kryptomon-fadeMount.png);
  position: absolute;
  top: 212px;
  left: auto;
  bottom: 0px;
  right: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto -25px;
  width: 116vw;
}

.HomePageHero .hero-image {
  position: absolute;
  top: 0px;
  left: auto;
  bottom: 0px;
  right: auto;
  background-image: url(../../images/kryptomon-hero.jpg);
  opacity: 0.4;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -2;
  background-position: center;
  margin: -345px auto 0px;
  min-width: 1664px;
  width: 100vw;
}

.HomePageContent .flash-container {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.HomePageContent .flash-container .play-game .button-play {
  margin-top: 10px;
  height: 38px;
  font-size: 16px;
}

.HomePageContent .play-game {
  /* position: absolute; */
  background: url(../../images/Play_Game.jpg) no-repeat center;
  background-size: 100%;
  width: 40%;
  height: 168px;
  z-index: 100;
  border-radius: 10px;
  margin-right: 20px;
  padding: 40px;
  padding-top: 30px;
}

.HomePageContent .flash-container .become-trainer {
  width: 50%;
  line-height: 30px;
  font-family: var(--font-family);
  font-size: 30px;
  color: #e7e3d7;
}

.HomePageContent .flash {
  /* position: absolute; */
  background: url(../../images/Flash.jpg) no-repeat center;
  background-size: 100%;
  width: 40%;
  height: 168px;
  z-index: 100;
  border-radius: 10px;
  margin-left: 20px;
  text-align: center;
  position: relative;
}

.HomePageContent .flash-container .starting-date {
  font-family: 'Poppins';
  font-size: 15px;
  color: #F3BA2F;
  position: absolute;
  bottom: 30px;
  left: 35%;
}

.HomePageHero .grid-top {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../images/grid_top.png);
  opacity: 0.5;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.HomePageHero .dragons {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 525px;
  height: 60%;
  background-image: url(../../images/dragons.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}

.HomePageHero.dcl.hero .ui.header.hero-subtitle {
  font-family: inherit;
  margin-bottom: 24px;
  color: var(--secondary-text);
  max-width: 55%;
}

.HomePage .dcl.tabs {
  font-family: var(--font-poppins);
}

.HomePageContent {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: -296px;
}

.HomePageContent .bottom-bg {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
}

.HomePageContent .bottom-bg .bottom-bg-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(../../images/bottom-bg.jpg);
  background-size: cover;
  height: 50%;
  background-position: center;
  margin: 0px auto;
}

.bottom-wave-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(../../images/Blue_waves.png);
  background-size: cover;
  height: 30%;
  background-position: center;
  margin: 0px auto;
}

.HomePageContent .Footer {
  bottom: 0;
}

.HomePage-carousels-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HomePage-carousels-header-main{
  width: 45%;
}

.HomePage-carousels-header-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
}

.HomePage-carousels-header-text div{
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-poppins);
}

.HomePage-carousels-header-text p{
  font-family: var(--font-poppins);
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

@media (min-width: 1440px) {
  .HomePageHero.dcl.hero {
    height: 1147px;
    border: 0px;
  }

  .HomePageContent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: -448px;
  }
}

@media (max-width: 1440px) {
  .HomePageHero.dcl.hero {
    height: 988px;
  }

  .hero-fade {
    top: -73px;
  }

  .hero-logo {
    margin-top: 30px;
  }

  .hero-market {
    margin-top: 258px;
  }

  .HomePageHero .hero-image {
    margin: -611px auto 0px;
    min-width: 1442px;
    width: 100vw;
  }

  .HomePageContent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: -480px;
  }
}

@media (max-width: 919px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 60px;
    font-weight: 400;
    line-height: 55px;
    display: flex;
    justify-content: center;
    letter-spacing: normal;
  }

  .HomePageHero .hero-image {
    margin: -795px auto 0px;
    min-width: 1150px;
  }

  .hero-fade {
    top: -456px;
    width: 1000px;
    background-size: contain;
  }

  .HomePageHero .dragons {
    position: absolute;
    top: 33%;
    width: 380px;
  }
}

@media (max-width: 768px) {
  .HomePageHero.dcl.hero .ui.header.hero-subtitle {
    margin-top: 20px;
    max-width: 100%;
  }

  .HomePageContent .dcl.tabs {
    display: none;
  }
  
  .HomePage-carousels-header-main{
    width: 100%;
  }

  .homepage-horizontal-nft-scroll{
    width: 100%;
  }

  .HomePage-carousels-header{
    display: flex;
    flex-direction: column;
  }  

  .Slideshow .nfts {
    display: grid;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 92vw;
    grid-auto-flow: column;
  }

  .HomePageContent .flash-container {
    flex-direction: column;
  }

  .HomePageContent .play-game {
    width: 100%;
    border-radius: 30px;
    margin-right: 0px;
  }

  .HomePageContent .flash {
    width: 100%;
    border-radius: 30px;
    margin-left: 0px;
    margin-top: 20px;
  }

  .HomePageContent .flash-container .become-trainer {
    width: 50%;
    line-height: 30px;
    font-family: var(--font-family);
    font-size: 20px;
    color: #e7e3d7;
  }

  .HomePageContent .flash-container .play-game .button-play {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 55px;
    line-height: 50px;
    display: flex;
    justify-content: center;
  }

  .hero-logo {
    margin-top: 75px;
    position: absolute;
    height: 75px;
    width: 329px;
    margin-top: 69px;
    background-size: 329px 80px;
  }

  .hero-market {
    position: absolute;
    height: 110px;
    width: 250px;
    margin-top: 255px;
    background-size: 260px 105px;
  }

  .dcl.navbar .ui.container {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start !important;
    z-index: 10000;
  }

  .kryptomon.logo {
    width: 160px !important;
    height: 64px !important;
    margin-right: 0px !important;
  }

  .marketplace-sub-logo {
    width: 72px !important;
    height: 36px !important;
  }

  .dcl.user-menu-wrapper .activity-bell.item {
    /* display: none; */
  }

  .dcl.account-wrapper {
    /* display: none !important; */
  }

  .dcl.navbar-mobile-search {
    margin-left: 10px;
    margin-right: 10px;
  }

  .dcl.tabs.fullscreen {
    position: absolute;
    top: 0px;
    z-index: 999;
    margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
    margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
    width: 50% !important;
  }

  .dcl.tabs {
    position: absolute;
    top: 0px;
    z-index: 999;
    margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
    margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
    width: 50% !important;
    /* margin-top: 0px !important; */
  }

  .dcl.tabs-left.show {
    background-color: #000;
  }
}

@media (max-width: 540px) {
  .hero-market {
    margin-top: 280px;
  }
}

@media (max-width: 480px) {
  .hero-logo {
    margin-top: 108px;
  }

  .HomePageHero .dragons {
    position: absolute;
    top: 37%;
    width: 328px;
  }

  .hero-market {
    margin-top: 272px;
    background-size: 225px 90px;
    height: 98px;
  }

  .HomePageContent .play-game {
    width: 100%;
    height: 168px;
  }

  .HomePageContent .flash {
    width: 100%;
    height: 168px;
  }
}

@media (max-width: 425px) {
  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 335px;
  }
}

@media (max-width: 479px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 40px;
    line-height: 37px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 320px;
  }
}

@media (max-width: 320px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 40px;
    line-height: 37px;
    display: flex;
    justify-content: center;
  }

  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 290px;
  }
}