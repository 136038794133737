@import 'react-slideshow-image/dist/styles.css';
.bnft-detail .Row .Column {
  width: calc(100% - 272px);
}

.bnft-detail .bnft-container {
  flex-direction: row;
}

.bnft-detail .bnft-slider {
  max-width: 70%;
}
.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}
.each-slide-effect > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 750px;
  /* width: 50%; */
}

.bnft-detail .Row .Column {
  width: calc(100% - 272px);
}

.bnft-detail .bnft-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.bnft-detail .bnft-description-container {
  padding-left: 42px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.bnft-detail .ui.card.link.BNFTCard {
  height: 100%;
  align-self: flex-start;
}

.bnft-detail .ui.card.link.BNFTDetailsCard {
  height: 100%;
  align-self: flex-start;
}

.bnft-detail .bnft-description-content > h3 > strong {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.bnft-detail .bnft-description-content > p > span {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.LootboxDetails.grid-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }
  .bnft-detail .Row .Column {
    width: 100%;
  }
  .bnft-detail .bnft-slider {
    max-width: 100%;
  }
  .bnft-detail .bnft-description-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
