.LootboxDetails.grid-container {
  display: flex;
  justify-content: flex-end;
}

.LootboxDetails.grid-container .lootbox-actions-container {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  gap: 10px
}

.LootboxDetails.grid-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.LootboxDetails.grid-container .lootbox-action-button.buy-action .ui.primary.button {
  background: linear-gradient(#d7b731, #f25f31);
  width: 40px;
  height: 50px;
  color: #000;
  text-transform: uppercase;
}

.LootboxDetails.grid-container .lootbox-action-button.bid-action .ui.primary.button {
  width: 40px;
  height: 50px;
}