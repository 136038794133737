.Menu {
  --menu-item-width: 248px;
  --menu-sub-item-width: 232px;
  margin-right: 24px;
  width: var(--menu-item-width);
}

@media (max-width: 768px) {
  .Menu {
    --menu-item-width: 100%;
    --menu-sub-item-width: 100%;
    margin-right: 0px;
    width: var(--menu-item-width);
  }
}

ul.Menu {
  margin-left: -16px;
  padding: 0px;
}

@media (max-width: 768px) {
  ul.Menu {
    margin-left: 0px;
  }
}

ul.Menu li {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2;
  list-style: none;
  padding: 9px 16px;
  margin-bottom: 8px;
  cursor: pointer;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  ul.Menu li {
    padding: 9px 0px;
  }
}

ul.Menu li .ui.image {
  vertical-align: text-bottom;
}

ul.Menu li.sub {
  width: var(--menu-item-width);
  color: var(--secondary-text);
}

ul.Menu li.sub.sub-1 {
  margin-left: 16px;
  padding-left: 16px;
  width: calc(var(--menu-item-width) - 16px);
}

ul.Menu li.sub.sub-2 {
  margin-left: 32px;
  padding-left: 16px;
  width: calc(var(--menu-item-width) - 32px);
}
.VendorMenu ul.Menu li.sub.sub-1 {
  margin-left: 0px;
  padding-left: 32px;
  width: var(--menu-item-width);
}

.VendorMenu ul.Menu li.sub.sub-2 {
  margin-left: 0px;
  padding-left: 48px;
  width: var(--menu-item-width);
}

ul.Menu li .dropdown.icon {
  float: right;
}

ul.Menu li:hover,
ul.Menu li.active {
  color: white;
  font-weight: 600;
  background-color: var(--card);
}

ul.Menu li.active {
  cursor: default;
}

ul.Menu ul.submenu {
  padding-left: 0px;
}
