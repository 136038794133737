.Bid {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--card);
  margin-bottom: 20px;
}

.Bid .bid-row {
  display: flex;
}

.Bid .image {
  flex: none;
  height: 96px;
  width: 96px;
  position: relative;
}

.Bid .wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.Bid .wrapper .info {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Bid .wrapper .info .dcl.stats {
  width: 20%;
}

.Bid .wrapper .info .dcl.stats.from {
  width: 30%;
}

.Bid .wrapper .info .dcl.stats .ui.header {
  white-space: nowrap;
}

.Bid .wrapper .info .dcl.stats + .dcl.stats {
  margin-left: 48px;
}

.Bid .wrapper .info .dcl.stats .ui.header:not(.sub) {
  font-size: 17px;
  line-height: 26px;
}

.Bid .wrapper .info .dcl.stats .ui.header.sub {
  font-size: 13px;
  line-height: 18px;
}

.Bid .wrapper .info .dcl.stats .Profile {
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.Bid .wrapper .info .dcl.stats .Profile .name {
  color: var(--text);
}

.Bid .actions {
  flex: none;
  margin-left: 48px;
}

.Bid .actions .ui.button:not(.primary) {
  background-color: transparent;
  border: 2px solid var(--secondary-on-modal);
}

.Bid .actions .ui.button {
  width: 146px;
}

.Bid .actions .AcceptButton {
  display: inline-block;
  height: 48px;
}

.Bid .actions .ui.button + .ui.button,
.Bid .actions .AcceptButton + .ui.button {
  margin-left: 16px;
}

@media (max-width: 768px) {
  .Bid {
    height: auto;
  }

  .Bid .bid-row {
    flex-direction: column;
  }

  .Bid .image {
    width: 100%;
    height: auto;
  }

  .Bid .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .Bid .info {
    flex-direction: column;
    align-items: flex-start;
  }

  .Bid .wrapper .info .dcl.stats,
  .Bid .wrapper .info .dcl.stats.from {
    width: 100%;
  }

  .Bid .wrapper .info .dcl.stats + .dcl.stats {
    margin-left: 0px;
  }

  .Bid .actions {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
  }

  .Bid .actions .ui.button {
    width: 100%;
  }

  .Bid .actions .ui.button + .ui.button,
  .Bid .actions .AcceptButton + .ui.button {
    margin-top: 12px;
    margin-left: 0px;
  }
}
