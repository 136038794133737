.kryptomon.choose-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.kryptomon.choose-pair .card-area {
  display: flex;
  justify-content: center;
  width: 100%;
}

.kryptomon.choose-pair .card-area .empty-card-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 375px;
  width: 100%;
}

.kryptomon.choose-pair .card-area .empty-card-box .card-content {
  width: 200px;
}

.kryptomon.choose-pair .card-area .empty-card-box .card-title {
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  text-align: center;
  margin-bottom: 44px;
}

.kryptomon.choose-pair .card-area .remove-button {
  margin-top: 18px;
}

.kryptomon.choose-pair .card-area .remove-button .kicon {
  font-size: 12px;
  color: #676370;
  letter-spacing: 0;
}

.kryptomon.choose-pair .card-area .vertical-line {
  border-left: 1px solid rgba(151, 151, 151, 0.21);
}

.kryptomon.choose-pair .compare-button {
  background: linear-gradient(#D7B731, #F25F31) !important;
  color: var(--background) !important;
  margin: 15px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.choose-pair .card-area {
    display: block;
  }

  .kryptomon.choose-pair .card-area .vertical-line {
    border-top: 1px solid rgba(151, 151, 151, 0.21);
    border-left: 0px;
    margin-top: 44px;
    padding-top: 44px;
  }
}

@media (max-width: 768px) {
  .kryptomon.choose-pair .card-area {
    display: block;
  }

  .kryptomon.choose-pair .card-area .vertical-line {
    border-top: 1px solid rgba(151, 151, 151, 0.21);
    border-left: 0px;
    margin-top: 44px;
    padding-top: 44px;
  }
}