.meta-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .meta-container {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.top-meta-row-text {
  color: #ddd;
  font-family: var(--font-family);
  padding-right: 8px;
}

.small-row-text {
  color: #ddd;
  font-family: var(--font-family);
  font-size: 10px;
}

.elem-row-value {
  color: #fff;
  font-family: var(--font-family);
}

.stats-text {
  font-size: 14px;
}

.general-stats {
  width: 100%;
  max-width: 280px;
  margin-bottom: 2em;
  margin-inline-end: auto;
}

@media (max-width: 991px) {
  .general-stats {
    max-width: 370px;
  }
}

.flex-direction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-item:not(:last-child) {
  border-bottom: 1px solid #222;
  width: 100%;
}

.top-meta-img-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.top-meta-text-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.top-meta-text-group p {
  margin: 0 !important;
}

.top-meta-img-title img {
  margin: 1em;
}

.stats-description-text {
  font-size: 14px;
  color: #888;
  font-family: 'PT Mono', sans-serif;
}

.value-text {
  font-size: 16px;
  color: rgb(243, 186, 47);
  margin: 0 8px;
}
