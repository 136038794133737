.community-container {
    width: 100%;
    max-width: 1300px;
    justify-content: center;
}

.community-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.community-title p {
    font-family: var(--font-family);
    font-size: 70px;
    letter-spacing: -1.56px;
    line-height: 65px;
    margin: 0 8px;
}

@media (max-width: 960px) {
    .community-title p {
        font-size: 50px;
        letter-spacing: -1.56px;
        line-height: 55px;
        margin: 0 8px;
    }
}

.community-amount {
    color: #F3BB2B;
}

.community-row {
    display: flex;
}

.card-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3em;
}

.community-card {
    background-color: #1E1B1C;
    border-radius: 10px;
    width: 22%;
    height: 120px;
    margin: 1% 1.5%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    cursor: pointer;
}

.community-card p {
    font-family: 'PT Mono', sans-serif;
}

.community-followers {
    font-size: 18px;
    text-align: center;
}

@media (max-width: 960px) {
    .community-followers {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .community-card {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .community-card {
        width: 45%;
    }
    .community-title p {
        font-size: 30px;
        letter-spacing: -1.56px;
        line-height: 45px;
        margin: 0 8px;
    }
}