.Owner > a {
  display: flex;
  align-items: center;
}

.Owner label {
  text-transform: uppercase;
  color: var(--secondary-text);
  margin-right: 20px;
}

@media (max-width: 768px) {
  .Owner > a {
    flex-direction: column;
    align-items: flex-start;
  }

  .Owner label {
    margin-right: 0px;
    margin-bottom: 12px;
    font-size: 13px;
    line-height: 18px;
  }
}
