.slider-container {
  padding-left: 16px;
  min-height: 20px;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #676370;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #f231af;
  z-index: 2;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.thumb::-webkit-slider-thumb {
  background-color: #fff;
  border: 3px solid #f231af;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  transition: 300ms ease;
}

.thumb::-webkit-slider-thumb:hover {
  box-shadow: 0 0 1px 1px #f231af;
}

.inputs {
  display: flex;
  justify-content: space-between;
  flex: row;
}

.input {
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #f231af;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  padding: 6px 4px;
  outline: none;
  transition: 200ms ease;
}
.input:focus {
  box-shadow: 0 0 1px 1px #f231af;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input::selection {
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

label {
  font-size: 12px;
  color: var(--secondary-text);
}
