.NFTCard {
  overflow: hidden;
}

.pdp-related-card {
  margin: 0 10px !important;
  min-width: 192px;
}

.NFTCard .header,
.NFTCard .dcl.mana.inline {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
}

.NFTCard .header .title {
  flex: 1 2 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
}

.ui.card.NFTCard>.content {
  flex: none;
}

.ui.card.NFTCard>.content>.header:not(.ui),
.ui.cards>.card.NFTCard>.content>.header:not(.ui) {
  display: flex;
  margin-bottom: 6px;
}

.NFTCard .dcl.mana.inline {
  margin: 0px;
  flex: none;
}

.ui.cards>.ui.card.NFTCard.link .meta,
.ui.card.link .meta {
  margin-bottom: 4px;
}

.ui.cards>.ui.card.link,
.ui.card.link {
  height: 280px;
}

.ui.cards>.ui.card.NFTCard.link:hover .meta,
a.ui.card.link:hover .meta {
  color: var(--secondary-text);
}

.ui.cards>.ui.card,
.ui.card {
  background-color: #323132 !important;
  border: none !important;
}

.ui.card:hover {
  border: none !important;
}

.NFTCard .tags {
  margin-top: 12px;
}

.NFTCard .NFTImage {
  overflow: hidden;
}

.NFTCard .NFTImage .ens-subdomain {
  background-size: 557px;
  background-position-y: -133px;
}

.NFTCard .NFTImage .ens-subdomain .monospace {
  margin-top: 0px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .NFTCard .header {
    display: flex;
    flex-direction: column;
  }

  .NFTCard .header .title {
    margin-right: 0;
  }

  .NFTCard .dcl.mana.inline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}

@media (max-width: 768px) {

  .NFTCard.ui.card>.content,
  .ui.cards>.NFTCard.card>.content {
    display: block;
  }

  .ui.cards>.ui.card {
    width: 46% !important;
    margin-right: 5px !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #323132;
  box-shadow: none;
}

.card-footer {
  background-color: #e7e3d7;
}

.card-image-container {
  width: 100%;
  height: 230px;
  position: relative;
}

.card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2d2e;
}

.card-image-text {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-self: flex-end;

  padding: 13px 16px;
}

.product-type-price-container {
  display: flex;
  justify-content: space-between;
}

.product-type-price {
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #232123;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
}

.product-type-price-short {
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #232123;
  width: 58px;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
}

.nft-price {
  text-align: center;
  height: fit-content;
  background-color: #39c876;
  padding: 0px 8px;
  color: #232123;
  width: 350px;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
  margin-top: 7px;
}

.product-type-icon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-image: url(../../images/egg/elem-fire.svg);
  display: flex;
  align-self: flex-end;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.product-info-value {
  font-size: 10px;
  margin: 0;
}

.product-info-number-card {
  margin: 0;
  font-size: 17px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.product-verified {
  width: 17px;
  height: 16px;
  background-image: url(../../images/egg/certified-badge.svg);
  margin: 8px;
}

.product-title {
  color: black;
}

.product-description-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 10px 18px;
  background-color: #e7e3d7;
}

.product-info-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.product-info-name-container .Row {
  align-items: center;
  width: 60%;
}

.product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.product-description-left {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 10px;
}

.product-description-left-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  font-family: var(--font-monospace);
}

.product-description-left-item p {
  color: #000000;
  font-size: 10px;
  margin: 0;
  font-family: var(--font-monospace);
}

.product-description-left-item-white {
  font-size: 10px;
  text-align: center;
  width: 32px;
  margin-top: 5px;
}

.product-description-left-item-empty {
  color: #e7e3d7;
  font-size: 10px;
  margin: 0;
}

.product-description-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #000000;
  font-size: 8px;
}

.product-type-breedable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.breedable-heart {
  width: 32px;
  height: 32px;
  margin-top: 10px;
}