.elems-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #323132;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-subcontainer {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #323132;
  border-radius: 30px;
  position: relative;
  width: 45%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-title {
  margin-left: 10px;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

.gamedata-subcontainer .elem-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px 5px 10px;
}

@media (max-width: 992px) {
  .elem-row {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .elems-container {
    max-width: 100%;
  }
  .elem-row {
    width: 33%;
  }
  .gamedata-subcontainer {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.game-row-text {
  color: #3898FF;
  font-family: var(--font-family);
  padding-right: 8px;
}

.game-row-value {
  color: #fff;
  font-family: var(--font-family);
}
