.link-group p {
    font-family: 'PT Mono', sans-serif;
}

.newsletter-area p{
    font-family: 'PT Mono', sans-serif;
}

.bottom-footer p {
    font-family: 'PT Mono', sans-serif;
}