.dna-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  border-radius: 8px;
  position: relative;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .dna-container {
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* @media (max-width: 1201px) {
  .dna-container {
    width: 554px;
    left: 30px;
  }
} */

/* @media (max-width: 991px) {
  .dna-container {
    width: 435px;
    height: 270px;
    padding: 25px 5px 5px 5px;
  }
} */

/* @media (max-width: 768px) {
  .dna-container {
    width: 90%;
    left: 36px;
    top: 85px;
  }
} */

/* @media (max-width: 650px) {
  .dna-container {
    width: 85%;
    padding: 25px 5px 5px 5px;
    left: 43px;
    top: 63px;
  }
} */

/* @media (max-width: 480px) {
  .dna-container {
    width: 88%;
    left: 18px;
    top: 63px;
  }
} */

/* @media (max-width: 425px) {
  .dna-container {
    width: 72%;
    left: 41px;
    top: 50px;
  }
} */

/* @media (max-width: 375px) {
  .dna-container {
    width: 76%;
    left: 34px;
    top: 50px;
  }
} */
/* @media (max-width: 425px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
}

@media (max-width: 375px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
}

@media (max-width: 320px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
} */

.dna-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .dna-info {
    top: 10px;
    right: 10px;
  }
}

.dna-info-generation {
  padding: 0.5px 10px;
  background-color: #39c876;
  border-radius: 10px;
  font-size: 10px;
  font-family: var(--font-monospace);
  font-weight: 700;
  color: #232123;
}

.dna-info-start {
  margin-left: 9px;
}
