.CraftingModal {
  position: relative !important;
}

.CraftingModal .item-image.craft {
  background-image: url('../../../images/items/crafting-loading.svg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 500px;
  border: 2px solid #d7319e;
  border-radius: 8px;
}

.CraftingModal .item-image.buy {
  background-image: url('../../../images/items/item-buy-loading.svg');
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 500px;
  border: 2px solid #d7319e;
  border-radius: 8px;
}

.CraftingModal .item-image > img {
  width: 125px;
  object-fit: contain;
}

.ball {
  width: 125px;
  /* height: 100px; */
  /* border-radius: 50%; */
  /* background-color: #ff5722; */
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 100px, 0);
  }
  to {
    transform: translate3d(0, 10px, 0);
  }
}
/* Prefix Support */

ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }
  .blueprint-item-description {
    margin-left: 0px;
  }
  .CraftingModal .item-image.craft {
    background-size: auto;
    width: 100%;
    height: 500px;
  }
  .CraftingModal .item-image.buy {
    background-size: auto;
    width: 100%;
    height: 500px;
  }
}
