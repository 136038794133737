.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .description-container {
    width: 100%;
  }
}

.show-text {
  font-size: 14px;
  color: #676370;
  font-weight: 400;
  font-family: var(--font-monospace);
}

.hidden-text {
  max-height: 160px;
  overflow: hidden;
  position: relative;
}

.hidden-text-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #232123 9%, rgba(35, 33, 35, 0) 97%);
}

.more-button {
  /* align-self: center; */
  font-family: var(--font-monospace);
  font-size: 15px;
  font-weight: 400;
  border: none;
  text-transform: capitalize;
  background-color: #000;
  color: #f231af;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
