.Header {
  width: 100%;
  height: auto;
  background-color: #323132;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-title {
  font-family: var(--font-nikkyou);
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
  margin-top: 20px;
}

.subtitle-text {
  font-family: 'PT Mono';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 25px;
}

.subtitle-text .second-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle-text .second-container > img {
  margin: 10px 10px 10px 0px;
}

.subtitle-text .second-container > div {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.subtitle-text .second-container .second-bold {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}

.subtitle-text > p {
  padding-top: 20px;
}

@media (max-width: 767px) {
  .Header {
    margin-top: auto;
  }
  .header-title {
    font-size: 30px;
    margin-top: 0px;
  }
  .subtitle-text {
    font-size: 12px;
  }
}
