.SaleItemPage .PageHeader {
  display: flex;
  align-items: center;
  background-color: var(--card);
}

.SaleItemPage .PageHeader > div {
  margin: auto;
  text-align: center;
}

.SaleItemPage ul.Menu {
  margin-top: 0;
}

.SaleItemPage .blockie-address {
  margin-top: 16px;
  font-size: 17px;
}

.SaleItemPage .profile-name {
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
}

.SaleItemPage .profile-address {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.SaleItemPage .profile-address-hash {
  color: var(--secondary-text);
  margin-right: 5px;
}

.SaleItemPage .copy {
  color: var(--primary);
  cursor: pointer;
}

.SaleItemPage .copied {
  color: var(--primary);
}

.SaleItemPage .profile-copied-text-desktop {
  position: absolute;
}

.SaleItemPage .profile-copy-text-mobile {
  margin-top: 5px;
}
