.SettingsPageMain {
  position: relative;
}

.SettingsPageMain .dcl.tabs {
  margin-top: 50px;
}

.SettingsPage .left-column {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.SettingsPage .row {
  margin-bottom: 70px;
}

.SettingsPage .dcl.blockie {
  margin-right: 24px;
  float: left;
}

.SettingsPage .address-container {
  margin-top: 7px;
  float: left;
}

.SettingsPage .address {
  margin-bottom: 8px;
  font-size: 21px;
}

.SettingsPage .username-container {
  margin-bottom: 20px;
}

.SettingsPage .username-container .username-title {
  font-family: var(--font-family);
  color: var(--secondary-text);
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  text-transform: uppercase;
}

.SettingsPage .username-container .username-content {
  padding: 20px 20px 20px 20px;
  font-family: "PT Mono";
  color: var(--secondary-text);
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}

.SettingsPage .copy-text,
.SettingsPage .link {
  color: var(--primary);
}

.SettingsPage .link {
  cursor: pointer;
}

.SettingsPage .balance {
  display: flex;
  align-items: center;
}

.SettingsPage .dcl.mana {
  font-size: 21px;
  margin-bottom: 0;
}

.SettingsPage .buy-more {
  margin-left: 24px;
}

.SettingsPage .authorization-checks>label {
  display: block;
  font-size: 17px;
  margin-bottom: 17px;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .SettingsPage .ui.grid>.row {
    flex-direction: column;
    margin-bottom: 48px;
  }

  .SettingsPage .ui.grid>.row>.column:first-child {
    margin-bottom: 16px;
  }

  .SettingsPage .blockie-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .SettingsPage .address-container {
    margin-top: 24px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .SettingsPage .address-container .address {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}