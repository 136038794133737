@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
.nft-start-game-button,
.nft-start-game-button:hover,
.nft-start-game-button:active,
.nft-start-game-button:focus {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(#d7b731, #f25f31);
    border-radius: var(--button-border-radius);
    color: #232123;
    justify-content: center;
    height: var(--button-height);
    font-size: var(--font-size-medium);
    font-family: var(--font-monospace);
    text-transform: none;
    text-transform: initial;
    transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    width: var(--button-width);
    margin-left: 10px;
}

@media (max-width: 768px) {
    .nft-start-game-button,
    .nft-start-game-button:hover,
    .nft-start-game-button:active,
    .nft-start-game-button:focus {
        position: absolute;
        top: 0px;
        right: 5px;
        margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
        margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
        width: 80px;
        height: 40px;
        font-size: 10px;
    }
}
.PageCustomHeader .dcl.navbar .ui.container {
  align-items: baseline;
}

.dcl.navbar .ui.container {
  align-items: baseline !important;
}

.dcl.navbar .ui.menu .item.item {
  font-size: 20px;
  margin-left: 80px;
}

.PageCustomHeader .dcl.navbar .ui.menu.secondary {
  -webkit-transform: translateY(14px);
          transform: translateY(14px);
}

.dcl.navbar .ui.menu.secondary {
  -webkit-transform: translateY(14px) !important;
          transform: translateY(14px) !important;
}

.dcl.navbar-logo.navbar-logo {
  margin-top: 4px;
}

.navbar-account .nft-start-game-button{
  font-family: 'PT Mono', sans-serif;
  color: black;
  font-size: 16.8px;
  line-height: 18px;
  font-weight: 700px;
}

.dcl .navbar-account .item .sign-in-button a{
  font-family: 'PT Mono', sans-serif;
  color: #fff;
  font-size: 16.8px;
  line-height: 18px;
  font-weight: 700px;
}

.dcl.navbar .ui.menu .item .bell {
  color: var(--text);
}

.dcl.navbar .ui.menu .item.active .bell {
  color: var(--primary);
}

.dcl.navbar .ui.menu .item .bell.pending:after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--primary);
  position: absolute;
  border-radius: 100%;
  top: -5px;
  right: -5px;
}

.dcl.account-wrapper .dcl.mana {
  flex: none;
}

.dcl.navbar .ui.menu .item.sign-in-button {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.dcl.navbar .ui.menu .item.sign-in-button,
.dcl.navbar .ui.menu .item.sign-in-button:hover,
.dcl.navbar .ui.menu .item.sign-in-button:active,
.dcl.navbar .ui.menu .item.sign-in-button:focus {
  background: linear-gradient(#d7319e, #6331f2);
  border-radius: var(--button-border-radius);
  color: #ffff;
  justify-content: center;
  height: var(--button-height);
  font-size: var(--font-size-medium);
  text-transform: none;
  text-transform: initial;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  width: var(--button-width);
}

.dcl.navbar .ui.menu .item.sign-in-button:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.dcl.navbar .ui.menu .item.start-game-button {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  margin-left: 10px;
}

.dcl.navbar .ui.menu .item.start-game-button,
.dcl.navbar .ui.menu .item.start-game-button:hover,
.dcl.navbar .ui.menu .item.start-game-button:active,
.dcl.navbar .ui.menu .item.start-game-button:focus {
  background: linear-gradient(#d7b731, #f25f31);
  border-radius: var(--button-border-radius);
  color: #232123;
  justify-content: center;
  height: var(--button-height);
  font-size: var(--font-size-medium);
  text-transform: none;
  text-transform: initial;
  transition: background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  width: var(--button-width);
  margin-left: 10px;
}

.dcl.navbar .ui.menu .item.start-game-button:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.dcl.navbar .ui.menu.secondary {
  -webkit-transform: translateY(28px);
          transform: translateY(28px);
}

.dcl.navbar .TextFilter .text-input {
  background-color: #323132;
  border-radius: 17px;
  padding-left: 5px;
  padding-right: 70px;
}

.dcl.navbar .TextFilter .text-input input {
  font-size: 15px;
}

@media (max-width: 768px) {
  .PageCustomHeader .dcl.navbar .ui.container {
    flex-direction: column;
  }

  .navbar-account .nft-start-game-button{
    font-family: 'PT Mono', sans-serif;
    color: black;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .dcl.navbar .ui.container {
    display: block !important;
    align-items: baseline !important;
  }

  .kryptomon.logo {
    margin-right: 20px !important;
    width: 82px !important;
    height: 32px !important;
    background-repeat: no-repeat;
  }

  .dcl.navbar .navbar-mobile-menu .ui.header {
    margin-left: 80px !important;
  }

  .dcl.navbar .mobile-menu {
    z-index: 100000;
    margin-top: -20px;
    padding-left: 70px !important;
  }

  .dcl.navbar .mobile-menu .item {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
  }

  .marketplace-sub-logo {
    display: block !important;
    width: 58px !important;
    height: 28px !important;
  }

  .dcl.navbar .ui.menu .item.item {
    font-size: 20px;
    margin-left: 40px;
    font-family: var(--font-poppins);
    text-transform: none;
    text-transform: initial;
    font-size: 21px;
    font-weight: 900;
  }

  .dcl.navbar .ui.menu .item.sign-in-button,
  .dcl.navbar .ui.menu .item.sign-in-button:hover,
  .dcl.navbar .ui.menu .item.sign-in-button:active,
  .dcl.navbar .ui.menu .item.sign-in-button:focus {
    height: var(--button-height);
    font-size: 12px;
    width: 75px;
    height: 30px;
    margin-left: 0px;
  }

  .dcl.navbar .ui.menu .item.start-game-button,
  .dcl.navbar .ui.menu .item.start-game-button:hover,
  .dcl.navbar .ui.menu .item.start-game-button:active,
  .dcl.navbar .ui.menu .item.start-game-button:focus {
    height: var(--button-height);
    font-size: 12px !important;
    width: 80px;
    height: 30px;
    margin-left: 10px;
  }

  .dcl.navbar-account {
    /* margin-top: 17px; */
    /* position: absolute; */
    width: 100%;
    right: 5px;
    top: calc(var(--navbar-margin-bottom) / 2) !important;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .dcl.navbar-account > div {
    justify-content: space-between !important;
  }

  .dcl.navbar-account .ui.secondary.menu {
    flex-direction: column;
  }

  .dcl.navbar .ui.menu.secondary {
    margin-top: 0px !important;
  }

  .dcl.navbar .ui.menu .item.start-game-button {
    width: 75px;
    height: 30px;
    margin-left: 0px !important;
    margin-top: 5px;
  }

  .PageCustomHeader .dcl.navbar .ui.menu.secondary {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
  }

  .dcl.navbar .ui.menu.secondary {
    margin-top: 0px !important;
  }

  .dcl.tabs-left {
    background-color: #000;
  }
}

@media (min-width: 768px) {
  .PageCustomHeader .dcl.navbar .dcl.navbar-account {
    margin-top: 25px;
  }
}
.link-group p {
    font-family: 'PT Mono', sans-serif;
}

.newsletter-area p{
    font-family: 'PT Mono', sans-serif;
}

.bottom-footer p {
    font-family: 'PT Mono', sans-serif;
}
.Coordinate_pin__1IxsY {
  background-image: url(/static/media/pin-land.76bb433b.svg);
  width: 17px;
  height: 16px;
  background-size: 19px;
  background-position: -2px -1px;
}

.AtlasPopup {
  position: absolute;
  z-index: 100;
  padding: 20px;
  background: black;
  margin-left: -320px;
  margin-top: -60px;
  max-width: 280px;
  transition: opacity 0.2s ease, top 0.2s ease, left 0.2s ease,
    margin-left 0.2s ease;
  border-radius: 8px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  text-align: left;
}

.AtlasPopup.right {
  margin-left: 40px;
}

.AtlasPopup .land-name .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.AtlasPopup .coordinates {
  font-size: 12px !important;
  line-height: 13px !important;
  padding: 3px 5px !important;
  margin-left: 8px;
  height: 24px !important;
  font-weight: 500 !important;
}

.AtlasPopup .dcl.section.medium:last-child {
  margin-bottom: 0px;
}

.AtlasPopup .price .dcl.mana {
  font-size: 14px;
}

.AtlasPopup.no-owner .owner {
  display: none;
}

.AtlasPopup.no-owner .land-name {
  margin-bottom: 0px;
}

.atlas-wrapper {
  position: absolute !important;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.Menu {
  --menu-item-width: 248px;
  --menu-sub-item-width: 232px;
  margin-right: 24px;
  width: var(--menu-item-width);
}

@media (max-width: 768px) {
  .Menu {
    --menu-item-width: 100%;
    --menu-sub-item-width: 100%;
    margin-right: 0px;
    width: var(--menu-item-width);
  }
}

ul.Menu {
  margin-left: -16px;
  padding: 0px;
}

@media (max-width: 768px) {
  ul.Menu {
    margin-left: 0px;
  }
}

ul.Menu li {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2;
  list-style: none;
  padding: 9px 16px;
  margin-bottom: 8px;
  cursor: pointer;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  ul.Menu li {
    padding: 9px 0px;
  }
}

ul.Menu li .ui.image {
  vertical-align: text-bottom;
}

ul.Menu li.sub {
  width: var(--menu-item-width);
  color: var(--secondary-text);
}

ul.Menu li.sub.sub-1 {
  margin-left: 16px;
  padding-left: 16px;
  width: calc(var(--menu-item-width) - 16px);
}

ul.Menu li.sub.sub-2 {
  margin-left: 32px;
  padding-left: 16px;
  width: calc(var(--menu-item-width) - 32px);
}
.VendorMenu ul.Menu li.sub.sub-1 {
  margin-left: 0px;
  padding-left: 32px;
  width: var(--menu-item-width);
}

.VendorMenu ul.Menu li.sub.sub-2 {
  margin-left: 0px;
  padding-left: 48px;
  width: var(--menu-item-width);
}

ul.Menu li .dropdown.icon {
  float: right;
}

ul.Menu li:hover,
ul.Menu li.active {
  color: white;
  font-weight: 600;
  background-color: var(--card);
}

ul.Menu li.active {
  cursor: default;
}

ul.Menu ul.submenu {
  padding-left: 0px;
}

.MenuItem {
  display: flex;
}

.MenuItem .content {
  display: inline-block;
  flex: 1 1 auto;
  align-self: center;
  line-height: 24px;
}

.MenuItem .content-main {
  display: inline-block;
  flex: 1 1 auto;
  align-self: center;
  line-height: 24px;
  font-weight: bold;
}

.MenuItem .ui.image {
  margin-right: 15px;
  vertical-align: middle;
}

.MenuItem .content .subtitle {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-text);
}
.slider-container {
  padding-left: 16px;
  min-height: 20px;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #676370;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #f231af;
  z-index: 2;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.thumb::-webkit-slider-thumb {
  background-color: #fff;
  border: 3px solid #f231af;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.thumb::-webkit-slider-thumb:hover {
  box-shadow: 0 0 1px 1px #f231af;
}

.inputs {
  display: flex;
  justify-content: space-between;
  flex: row 1;
}

.input {
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #f231af;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  padding: 6px 4px;
  outline: none;
  transition: 200ms ease;
}
.input:focus {
  box-shadow: 0 0 1px 1px #f231af;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input::selection {
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

label {
  font-size: 12px;
  color: var(--secondary-text);
}

.Menu .dropdown-sub {
  margin-left: 20px;
}
.checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 16px;
}

@media (max-width: 768px) {
  .checkboxes {
    padding-left: 0px;
  }
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  flex: 50% 1;
  margin: 4px 0;
}

.icon {
  visibility: visible;
  line-height: normal;
}

.hidden-icon {
  visibility: hidden;
}

.checkbox-passive {
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #f231af;
  border-radius: 3px;
  transition: all 150ms;
  padding: 2px;
  margin-right: 8px;
}

.checkbox-active {
  background: #f231af;
  position: relative;
}

.checkbox-active::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 3px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 300ms ease-in-out;
          animation: antCheckboxEffect 300ms ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}

.labelContainer {
  display: flex;
  flex-direction: row;
}

.label {
  color: var(--secondary-text);
  font-size: 12px;
  font-weight: 600;
}

.labelContainer .product-type-icon-details {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-top: 2.5px;
  margin-left: 5px;
  background-image: url(/static/media/elem-fire.e1d0696b.svg);
  display: flex;
  /* align-self: flex-start; */
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
    visibility: visible;
  }

  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.elemTypes {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 8px;
}

.clear-filters {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.slider-container {
  padding-left: 8px;
  width: 100%;
  position: relative;
  max-width: 210px;
}

@media (max-width: 768px) {
  .slider-container {
    padding-left: 0px;
    max-width: 100%;
  }
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #676370;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #f231af;
  z-index: 2;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.thumb::-webkit-slider-thumb {
  background-color: #fff;
  border: 3px solid #f231af;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.thumb::-webkit-slider-thumb:hover {
  box-shadow: 0 0 1px 1px #f231af;
}

.inputs {
  display: flex;
  justify-content: space-between;
  flex: row 1;
}

.input {
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #f231af;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  padding: 6px 4px;
  outline: none;
  transition: 200ms ease;
}
.input:focus {
  box-shadow: 0 0 1px 1px #f231af;
}

.radio-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
}

.input::selection {
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

label {
  font-size: 12px;
  color: var(--secondary-text);
}

.VendorMenu > .Menu {
  background-color: none;
  border: 1px solid #35323b;
  border-radius: 9px;
  font-weight: 600;
}

.VendorMenu > .Menu .Menu {
  margin: 0;
}

.VendorMenu.active > .Menu {
  border: none;
  background-color: var(--card);
}

.VendorMenu.disabled .MenuItem {
  cursor: default;
}

.VendorMenu.disabled .MenuItem:hover {
  background-color: transparent;
}

.VendorMenu .MenuItem .ui.image {
  width: 50px;
  height: 50px;
}

.VendorMenu .MenuItem {
  margin-bottom: 0;
}

.VendorMenu.active > .Menu .MenuItem.active {
  background-color: rgb(54, 51, 60);
}

.VendorMenu .MenuItem.vendor-menu-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.NFTSections .MenuItem {
  font-family: var(--font-poppins);
  border-radius: 8px;
}

.VendorMenu .NFTSections .MenuItem {
  border-radius: 0px;
}

.VendorMenu .NFTSections > .MenuItem:last-child {
  border-radius: 0px 0px 8px 8px;
}

.VendorMenu .NFTSections .MenuItem:after {
  content: '';
  display: none;
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  width: 8px;
  height: 14px;
  top: 6px;
  position: relative;
}

.VendorMenu:hover .NFTSections .MenuItem:hover:after,
.VendorMenu .NFTSections .MenuItem.active:after {
  display: inline-block;
  flex: 1 1 auto;
}

.VendorMenu:hover .NFTSections .MenuItem:hover .dropdown.icon,
.VendorMenu .NFTSections .MenuItem.active .dropdown.icon {
  display: none;
}

.NFTImage {
  position: relative;
  height: 100%;
  width: 100%;
}

.NFTImage .pixel {
  display: block;
  width: 100%;
  height: auto;
}

.NFTImage .image-wrapper {
  text-align: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.NFTImage .image {
  margin: auto;
  max-height: 100%;
}

.NFTImage .rarity-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.NFTImage .ens-subdomain {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  height: 100%;
  background-image: url(/static/media/logo_dark.ced4e507.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #37333d;
  color: white;
  flex-direction: column;
}

.NFTImage .ens-subdomain .name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 16px 16px 16px;
}

.NFTImage .ens-subdomain.small .name {
  text-overflow: unset;
  padding: 10px 0px;
  font-size: 60%;
}

.NFTImage .ens-subdomain .monospace {
  font-family: 'Inconsolata', monospace;
  font-size: 17px;
  line-height: 24px;
  margin-top: 12px;
  color: var(--secondary-text);
  padding: 0px 16px 0px 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: lowercase;
}

.NFTCard {
  overflow: hidden;
}

.pdp-related-card {
  margin: 0 10px !important;
  min-width: 192px;
}

.NFTCard .header,
.NFTCard .dcl.mana.inline {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
}

.NFTCard .header .title {
  flex: 1 2 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
}

.ui.card.NFTCard>.content {
  flex: none;
}

.ui.card.NFTCard>.content>.header:not(.ui),
.ui.cards>.card.NFTCard>.content>.header:not(.ui) {
  display: flex;
  margin-bottom: 6px;
}

.NFTCard .dcl.mana.inline {
  margin: 0px;
  flex: none;
}

.ui.cards>.ui.card.NFTCard.link .meta,
.ui.card.link .meta {
  margin-bottom: 4px;
}

.ui.cards>.ui.card.link,
.ui.card.link {
  height: 280px;
}

.ui.cards>.ui.card.NFTCard.link:hover .meta,
a.ui.card.link:hover .meta {
  color: var(--secondary-text);
}

.ui.cards>.ui.card,
.ui.card {
  background-color: #323132 !important;
  border: none !important;
}

.ui.card:hover {
  border: none !important;
}

.NFTCard .tags {
  margin-top: 12px;
}

.NFTCard .NFTImage {
  overflow: hidden;
}

.NFTCard .NFTImage .ens-subdomain {
  background-size: 557px;
  background-position-y: -133px;
}

.NFTCard .NFTImage .ens-subdomain .monospace {
  margin-top: 0px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .NFTCard .header {
    display: flex;
    flex-direction: column;
  }

  .NFTCard .header .title {
    margin-right: 0;
  }

  .NFTCard .dcl.mana.inline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}

@media (max-width: 768px) {

  .NFTCard.ui.card>.content,
  .ui.cards>.NFTCard.card>.content {
    display: block;
  }

  .ui.cards>.ui.card {
    width: 46% !important;
    margin-right: 5px !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #323132;
  box-shadow: none;
}

.card-footer {
  background-color: #e7e3d7;
}

.card-image-container {
  width: 100%;
  height: 230px;
  position: relative;
}

.card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2d2e;
}

.card-image-text {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-self: flex-end;

  padding: 13px 16px;
}

.product-type-price-container {
  display: flex;
  justify-content: space-between;
}

.product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #232123;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
}

.product-type-price-short {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #232123;
  width: 58px;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
}

.nft-price {
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 0px 8px;
  color: #232123;
  width: 350px;
  border-radius: 10px;
  font-weight: 700;
  font-family: var(--font-monospace);
  font-size: 8px;
  line-height: 20px;
  margin-top: 7px;
}

.product-type-icon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-image: url(/static/media/elem-fire.e1d0696b.svg);
  display: flex;
  align-self: flex-end;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.product-info-value {
  font-size: 10px;
  margin: 0;
}

.product-info-number-card {
  margin: 0;
  font-size: 17px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.product-verified {
  width: 17px;
  height: 16px;
  background-image: url(/static/media/certified-badge.4b562c1b.svg);
  margin: 8px;
}

.product-title {
  color: black;
}

.product-description-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 10px 18px;
  background-color: #e7e3d7;
}

.product-info-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.product-info-name-container .Row {
  align-items: center;
  width: 60%;
}

.product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.product-description-left {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 10px;
}

.product-description-left-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  font-family: var(--font-monospace);
}

.product-description-left-item p {
  color: #000000;
  font-size: 10px;
  margin: 0;
  font-family: var(--font-monospace);
}

.product-description-left-item-white {
  font-size: 10px;
  text-align: center;
  width: 32px;
  margin-top: 5px;
}

.product-description-left-item-empty {
  color: #e7e3d7;
  font-size: 10px;
  margin: 0;
}

.product-description-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #000000;
  font-size: 8px;
}

.product-type-breedable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.breedable-heart {
  width: 32px;
  height: 32px;
  margin-top: 10px;
}
.Row {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  width: 100%;
}
.Row-space-between {
  justify-content: space-between;
}
.Row > .Column.grow {
  flex: 1 1 auto;
}

.Row > .Column.shrink {
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .Row > .Column.right {
    overflow: hidden;
  }
}

.Image {
  position: relative;
  height: 100%;
  width: 100%;
}

.Image .pixel {
  display: block;
  width: 100%;
  height: auto;
}

.Image .image-wrapper {
  text-align: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.Image .image {
  margin: auto;
  max-height: 100%;
}

.LootboxCard .Image .image {
  margin-top: 8%;
  max-height: 75%;
}
.ui.cards > .ui.card.link,
.ui.card.link.LootboxCard {
  box-shadow: var(--shadow-2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
  align-self: auto;
}

.LootboxCard .card-image-text.candy {
  align-items: flex-end;
}

.LootboxCard .product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #000000;
}

.LootboxCard .product-type-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;
  background: #6331f2;
  border-radius: 50px;
  color: #ffffff;
}

.LootboxCard .product-type-price-candy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.LootboxCard .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.LootboxCard .product-info-number-card {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LootboxCard .product-description-container {
  height: 78px;
  min-height: 78px;
  justify-content: center;
}

.LootboxCard .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.LootboxCard .product-description-left-item {
  color: #000000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.LootboxCard .product-description-right-nft {
  position: absolute;
  right: 20px;
  bottom: 5px;
  /* background-color: #6331f2; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex; */
}

.LootboxCard .product-description-right-nft > img {
  width: 31px;
  height: 36px;
}

.LootboxCard .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .ui.cards > .ui.card.link,
  .ui.card.link.LootboxCard {
    width: 100%;
  }
}

.justify-center {
  justify-content: center !important;
}

.IndexingDelayCard .card-image-text {
  color: var(--text);
}
.VendorStrip.Menu {
  display: flex;
  width: inherit;
  margin-left: 0;
}

.VendorStrip.Menu .MenuItem {
  padding: 5px 12px;
  margin-right: 15px;
  background-color: var(--card);
  display: flex;
  align-items: center;
}

.VendorStrip.Menu .MenuItem:not(.active):hover {
  font-weight: normal;
}

.VendorStrip.Menu .MenuItem img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.VendorStrip.Menu .MenuItem .content {
  font-size: 12px;
}


.NFTSidebar > .ui.header.sub {
  margin-top: 3px;
  margin-bottom: 22px;
}

.PartnerSidebar .go-back {
  margin-top: 3px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  color: var(--secondary-text);
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
}

.PartnerSidebar i.back.icon {
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  background-size: 6px;
}

.Chip,
.Chip.square {
  min-width: 10px;
  min-height: 26px;
  padding: 3px 11px;
}

.Chip.rectangle {
  min-width: 40px;
  min-height: 24px;
}

.Chip.circle {
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  padding: 11px 11px;
  background: var(--dark-two);
}

.Chip {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  background: rgba(var(--bluish-steel-raw), 0.64);
  color: #fff;
  display: inline-block;
}

.Chip.clickeable:hover {
  background: var(--bluish-steel);
}

.Chip.clickeable {
  cursor: pointer;
}

.Chip.active {
  background: rgba(var(--bluish-steel-raw), 0.32);
  color: var(--primary);
  cursor: default;
}
.Chip.active:hover {
  background: rgba(var(--bluish-steel-raw), 0.32);
}

.Chip.disabled {
  opacity: 0.5;
  cursor: default;
}

.Chip.disabled .icon {
  cursor: default;
}

.Chip .icon {
  vertical-align: middle;
  line-height: 1;
  margin-right: 0;
}

.Chip .text {
  font-size: 17px;
}

.TextFilter .text-input input {
  font-size: 17px;
  line-height: 20px;
  background: rgb(50, 50, 50);
  color: white;
  font-weight: 600;
  font-family: var(--font-monospace);
  border: none;
  border-radius: 20px;
  padding-left: 45px;
  outline: none;
  width: 100%;
  height: 40px;
  background-image: url(/static/media/search.15c090cb.svg);
  background-repeat: no-repeat;
  background-position-y: center !important;
  background-position-x: 18px;
}

.TextFilter .text-input input::-webkit-input-placeholder {
  color: var(--secondary-text);
  font-weight: 400;
}

.TextFilter .text-input input:-ms-input-placeholder {
  color: var(--secondary-text);
  font-weight: 400;
}

.TextFilter .text-input input::placeholder {
  color: var(--secondary-text);
  font-weight: 400;
}

@media (max-width: 768px) {
  .TextFilter {
    margin-bottom: 4px;
  }

  .TextFilter .ui.sub.header.name {
    font-weight: normal;
  }
}

.search-results-area {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: rgb(46, 45, 45);
  margin-top: 10px;
  max-height: 330px;
  max-width: 530px;
  width: 100%;
  position: absolute;
  z-index: 100;
}
.search-results-items-area {
  overflow-y: scroll;
}

.dropdown-item {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgb(60, 59, 62);
}

.dropdown-item:hover {
  background: rgb(60, 60, 60);
  border-radius: 5px;
}

.dropdown-item-text {
  font-size: 18px;
  margin: auto 0;
  padding-top: 5px;
  font-family: var(--font-family);
}

.dropdown-item-image {
  width: 50px;
}

.dropdown-item-gen {
  background-color: #33d669;
  color: black;
  padding: 0 10px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 10px;
}

.dropdown-item-heart {
  width: 32px;
  height: 32px;
}

.search-result-showmore-btn {
  position: relative;
  width: 100%;
  background-color: black;
  border: none;
  height: 60px;
  box-shadow: 2px 2px 12px 2px #222a;
  cursor: pointer;
  color: rgb(240, 50, 175);
  font-size: 16px;
  font-weight: 700;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-result-showmore-btn:hover {
  text-shadow: 0 0 15px rgb(240, 50, 175);
}

.search-result-showmore-btn:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.ArrayFilter .options {
  display: flex;
  flex-flow: wrap;
}

.ArrayFilter .options .option {
  padding: 5px 12px;
  background-color: #37333d;
  border-radius: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}

.ArrayFilter .options .option.selected {
  background-color: var(--primary);
}

@media (max-width: 768px) {
  .ArrayFilter {
    margin-bottom: 12px;
  }

  .ArrayFilter .options .option {
    font-size: 13px;
    line-height: 18px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .ArrayFilter .ui.sub.header.name {
    font-weight: normal;
  }
}

.SelectFilter .ui.dropdown {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  color: var(--secondary-text);
}

.SelectFilter .ui.dropdown .text {
  flex: 1 0 auto;
  color: var(--text);
}

.SelectFilter .ui.dropdown .dropdown.icon {
  color: var(--text);
}

@media (max-width: 768px) {
  .SelectFilter {
    margin-bottom: 24px;
    width: 100%;
  }

  .SelectFilter .ui.sub.header.name {
    font-weight: normal;
  }
}

.PriceChangeNotice.ui.card {
  /* This margin top is compensating for the @margin on semantic UI's .ui.cards. Can't really access the LESS variable */
  margin-top: -0.875em;
  margin-bottom: 30px;
}

.PriceChangeNotice.ui.card .meta {
  display: flex;
}

.PriceChangeNotice.ui.card .content .meta .message {
  flex: 1 1 auto;
}

.PriceChangeNotice.ui.card .icon.close {
  cursor: pointer;
}

@media (max-width: 768px) {
  .PriceChangeNotice.ui.card {
    flex: 1 1 auto;
  }
}

.NFTFilters.empty {
  margin-top: 12px;
}

.NFTFilters .topbar {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.NFTFilters .topbar .topbar-filter {
  margin-left: 20px;
}

.NFTFilters .topbar > .full-width,
.NFTFilters .topbar > .TextFilter {
  flex: 1 0 auto;
  margin-right: 30px;
}

.NFTFilters .topbar > .TextFilter .text-input input {
  padding-top: 0;
  font-size: 15px;
  background-position-y: 4px;
}

.NFTFilters .topbar .ui.dropdown {
  display: flex;
  line-height: 1;
}

.NFTFilters .Filter .ui.sub.header.name {
  margin-bottom: 12px !important;
  font-weight: 600;
}

.NFTFilters .filters {
  padding: 16px;
  background-color: var(--card);
  border-radius: 16px;
  margin-bottom: 32px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  padding-bottom: 8px;
  margin-top: -8px;
}

.NFTBrowse .filters .Filter + .Filter {
  margin-top: 14px;
}

.NFTFilters .filters .dcl.row {
  align-items: flex-start;
}

.NFTFilters .filters .dcl.row + .dcl.row {
  margin-top: 12px;
}

.NFTFilters .filters .dcl.row .Filter {
  flex: 1 0 auto;
}

.NFTFilters .filters .dcl.row .Filter + .Filter {
  margin-top: 0px;
  flex: none;
  width: 50%;
  margin-left: 24px;
}

.NFTFilters .ui.toggle.checkbox input.hidden:focus + label,
.NFTFilters .ui.toggle.checkbox.checked input.hidden + label,
.NFTFilters .ui.toggle.checkbox input.hidden + label,
.NFTFilters .ui.toggle.checkbox.checked input.hidden:focus + label {
  font-size: 12px;
  margin-right: 0px;
  padding-left: 33px;
  line-height: 12px;
  color: var(--primary) !important;
}

.NFTFilters .ui.toggle.checkbox input.hidden + label:before {
  width: 26px;
  height: 12px;
  background-color: black;
}

.NFTFilters .ui.toggle.checkbox input.hidden + label:after {
  background: var(--primary);
  width: 12px;
  height: 12px;
}

.NFTFilters .ui.toggle.checkbox label,
.NFTFilters .ui.dropdown > .text {
  text-transform: uppercase;
  font-family: var(--font-family);
}

.NFTFilters .filters .ui.dropdown .visible.menu {
  max-height: 500px;
  overflow: auto;
}

.NFTFilters .open-filters-wrapper {
  display: flex;
  position: relative;
  cursor: pointer;
}

.NFTFilters .open-filters-wrapper .label {
  color: var(--primary);
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: 500;
}

.NFTFilters .open-filters {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/filters.51c5d80b.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.NFTFilters .open-filters.active {
  background-image: url(/static/media/filters-active.b715262f.svg);
  background-size: 20px;
}

.NFTFilters .Chip.grid {
  border-radius: 4px 0 0 4px;
}

.NFTFilters .Chip.atlas {
  border-radius: 0 4px 4px 0;
}

/*------------------
  FiltersModal
 */

.FiltersModal .NFTSidebar ul.menu li:hover,
.FiltersModal .NFTSidebar ul.menu li.active {
  background-color: var(--secondary-on-modal);
}

.FiltersModal .filter-row {
  margin-bottom: 20px;
}

.FiltersModal .filter-row .ui.sub.header {
  margin-bottom: 12px;
}

.FiltersModal .NFTSidebar {
  width: 100%;
}

.FiltersModal .NFTSidebar ul.menu {
  width: calc(100% + 16px);
}

.FiltersModal .NFTSidebar ul.menu li {
  width: calc(100% + 16px);
}

.FiltersModal .NFTSidebar ul.menu li.sub {
  width: calc(100% + 0px);
}

.FiltersModal.ui.modal .dcl.close {
  position: absolute;
  right: 24px !important;
  top: 24px !important;
}

.FiltersModal .apply-filters {
  width: 100%;
  margin-top: 48px;
}

.FiltersModal .ui.dropdown {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid var(--divider);
  border-radius: 6px;
  color: var(--secondary-text);
}

.FiltersModal .ui.dropdown .visible.menu {
  max-height: 500px;
  overflow: auto;
}

.FiltersModal .ui.dropdown .text {
  flex: 1 0 auto;
  color: var(--text);
}

.FiltersModal .ui.dropdown .dropdown.icon {
  color: var(--text);
}

@media (max-width: 768px) {
  .NFTFilters .topbar {
    align-items: normal;
  }

  .FiltersModal .dcl.row {
    align-items: flex-start;
    flex-direction: column;
    justify-content: stretch;
  }
}

.ui.cards > .ui.card.link,
.ui.card.link.LootboxCard {
  box-shadow: var(--shadow-2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
  align-self: auto;
}

.LootboxCard .card-image-text.candy {
  align-items: flex-end;
}

.LootboxCard .product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #000000;
}

.LootboxCard .product-type-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;
  background: #6331f2;
  border-radius: 50px;
  color: #ffffff;
}

.LootboxCard .product-type-price-candy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.LootboxCard .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.LootboxCard .product-info-number-card {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LootboxCard .product-description-container {
  height: 78px;
  min-height: 78px;
  justify-content: center;
}

.LootboxCard .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.LootboxCard .product-description-left-item {
  color: #000000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.LootboxCard .product-description-right-nft {
  position: absolute;
  right: 20px;
  bottom: 5px;
  /* background-color: #6331f2; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex; */
}

.LootboxCard .product-description-right-nft > img {
  width: 31px;
  height: 36px;
}

.LootboxCard .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .ui.cards > .ui.card.link,
  .ui.card.link.LootboxCard {
    width: 100%;
  }
}

.NFTBrowse .Row .right.Column,
.NFTBrowse .ui.cards {
  position: relative;
}

.NFTBrowse .ui.cards {
  min-height: 150px;
}

.NFTBrowse .ui.cards+.empty {
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-text);
}

.NFTBrowse>.ui.container {
  margin-top: 20px;
}

.NFTBrowse .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--background);
  opacity: 0.6;
}

.NFTBrowse .load-more {
  text-align: center;
  margin-top: 36px;
  height: 46px;
}

.NFTBrowse .Atlas {
  min-height: 700px;
  height: 100%;
  position: relative;
}

.NFTBrowse .Atlas canvas {
  border-radius: 8px;
}

.NFTBrowse .fullscreen-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/static/media/fullscreen.6d910b69.svg);
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 100%;
  cursor: pointer;
}

.NFTBrowse.fullscreen .Atlas canvas {
  border-radius: 0px;
}

.NFTBrowse.fullscreen .fullscreen-button {
  display: none;
}

.NFTBrowse .topbar {
  margin-bottom: 24px;
}

.NFTBrowse.fullscreen>.Row,
.NFTBrowse.fullscreen>.Row>.Column,
.NFTBrowse.fullscreen .Atlas {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: auto;
  margin-top: 0px;
}

.NFTBrowse.fullscreen .blur-background {
  position: relative;
  background: rgba(22, 20, 26, 0.75);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 1;
}

.NFTBrowse.fullscreen .NFTFilters {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NFTBrowse.fullscreen .NFTFilters .topbar {
  margin-bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter {
  margin-bottom: 0px;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input {
  padding-bottom: 0px;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input::-webkit-input-placeholder {
  color: var(--text);
  opacity: 0.5;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input:-ms-input-placeholder {
  color: var(--text);
  opacity: 0.5;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input::placeholder {
  color: var(--text);
  opacity: 0.5;
}

@media (max-width: 768px) {
  .NFTBrowse .Layout .right.column>div {
    width: 100%;
  }

  .NFTBrowse .NFTSidebar ul.menu li {
    width: calc(100% - 32px);
  }

  .NFTBrowse .NFTFilters .topbar {
    align-items: center;
  }
}

.NFTBrowse.header {
  background-image: url(/static/media/kryptomon-hero.5645571c.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.NFTBrowse.header .dcl.tabs {
  font-family: var(--font-family);
  margin-top: 50px;
}

.browse-nft-list {
  margin-bottom: 400px;
}
.PageHeader {
  height: var(--page-header-height);
  margin-bottom: 35px;
}

.PageCustomHeader {
  background-image: url(/static/media/grid_top.bc4b39ca.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.PageCustomHeader .dcl.tabs {
  font-family: var(--font-poppins);
  margin-top: 50px;
}

.ui.secondary.stackable.menu {
  display: flex;
}

.hamburgerBtn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .hamburgerBtn {
    height: 60px;
    min-width: 30px;
    display: block;
    margin-left: 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAPKADAAQAAAABAAAAPAAAAACL3+lcAAAAwklEQVRoBe3XsRHCMAwFUJvsQAU1s2QRhmIRZqGGih04E3O4s3sr99I4p3Px9eRGKfkIECBAgAABAgQIECBAgAABAnsTyKOG3s/H+snllko5je5MWc/5tZR8PZ4v916+Q69YayGbrcG3Af2y1//ON2y4c3cXpWHD9Vmk7XmE6/L/pMPlFpgAAQIECBAgEF7AttRGaFtqEsFP21LwAYpPgAABAgQIEJhHwLbUZmFbahLBT9tS8AGKT4AAAQIECBCYR+ALA/1LFnBuJpoAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 30px 30px;
    border: none;
    cursor: pointer;
  }

  .PageCustomHeader {
    background-size: contain;
    position: relative;
  }

  .PageCustomHeader .dcl.tabs-left {
    display: none;
  }
}

.dcl.tabs-left.show {
  display: block;
}

.AccountPage .PageHeader {
  display: flex;
  align-items: center;
  background-color: var(--card);
}

.AccountPage .PageHeader > div {
  margin: auto;
  text-align: center;
}

.AccountPage ul.Menu {
  margin-top: 0;
}

.AccountPage .blockie-address {
  margin-top: 16px;
  font-size: 17px;
}

.AccountPage .profile-name {
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
}

.AccountPage .profile-address {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.AccountPage .profile-address-hash {
  color: var(--secondary-text);
  margin-right: 5px;
}

.AccountPage .copy {
  color: var(--primary);
  cursor: pointer;
}

.AccountPage .copied {
  color: var(--primary);
}

.AccountPage .profile-copied-text-desktop {
  position: absolute;
}

.AccountPage .profile-copy-text-mobile {
  margin-top: 5px;
}

.Header {
  width: 100%;
  height: auto;
  background-color: #323132;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-title {
  font-family: var(--font-nikkyou);
  color: #ffffff;
  font-weight: 400;
  font-size: 40px;
  margin-top: 20px;
}

.subtitle-text {
  font-family: 'PT Mono';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 25px;
}

.subtitle-text .second-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle-text .second-container > img {
  margin: 10px 10px 10px 0px;
}

.subtitle-text .second-container > div {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.subtitle-text .second-container .second-bold {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}

.subtitle-text > p {
  padding-top: 20px;
}

@media (max-width: 767px) {
  .Header {
    margin-top: auto;
  }
  .header-title {
    font-size: 30px;
    margin-top: 0px;
  }
  .subtitle-text {
    font-size: 12px;
  }
}

.SaleItemPage .PageHeader {
  display: flex;
  align-items: center;
  background-color: var(--card);
}

.SaleItemPage .PageHeader > div {
  margin: auto;
  text-align: center;
}

.SaleItemPage ul.Menu {
  margin-top: 0;
}

.SaleItemPage .blockie-address {
  margin-top: 16px;
  font-size: 17px;
}

.SaleItemPage .profile-name {
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
}

.SaleItemPage .profile-address {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.SaleItemPage .profile-address-hash {
  color: var(--secondary-text);
  margin-right: 5px;
}

.SaleItemPage .copy {
  color: var(--primary);
  cursor: pointer;
}

.SaleItemPage .copied {
  color: var(--primary);
}

.SaleItemPage .profile-copied-text-desktop {
  position: absolute;
}

.SaleItemPage .profile-copy-text-mobile {
  margin-top: 5px;
}

.AccountPage .PageHeader {
  display: flex;
  align-items: center;
  background-color: var(--card);
}

.AccountPage .PageHeader > div {
  margin: auto;
  text-align: center;
}

.AccountPage ul.Menu {
  margin-top: 0;
}

.AccountPage .blockie-address {
  margin-top: 16px;
  font-size: 17px;
}

.AccountPage .profile-name {
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
}

.AccountPage .profile-address {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.AccountPage .profile-address-hash {
  color: var(--secondary-text);
  margin-right: 5px;
}

.AccountPage .copy {
  color: var(--primary);
  cursor: pointer;
}

.AccountPage .copied {
  color: var(--primary);
}

.AccountPage .profile-copied-text-desktop {
  position: absolute;
}

.AccountPage .profile-copy-text-mobile {
  margin-top: 5px;
}

.SignInPage {
  display: flex;
  justify-content: center;
}

.SignInPage .youtube {
  pointer-events: all;
  margin-top: 20px;
  margin-bottom: 35px;
}

.SignInPage .SignIn.center {
  position: relative;
}

.dcl.login-modal .dcl.option.magic_auth .input {
  border: none;
}

.dcl.login-modal .dcl.option.magic_auth p.message {
  display: none;
}

.dcl.login-modal .dcl.option.magic_auth .dcl.field .overlay,
.ui.form .dcl.field .overlay {
  bottom: 12px;
}

.dcl.login-modal .title {
  margin: 0px;
}

.dcl.login-modal .dcl.option.magic_auth .ui.basic.button {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .dcl.login-modal {
    width: 400px !important;
  }

  .SignIn .ui.header {
    font-size: 16px !important;
  }

  .SignInPage .youtube {
    width: 90%;
  }
}
.Authorization > .field {
  margin-bottom: 41px !important;
}

.Authorization .ui.checkbox input.hidden + label {
  font-weight: 700;
  font-size: 17px;
  padding-left: 35px;
}

.Authorization .is-pending .ui.checkbox label:before,
.Authorization .is-pending .ui.checkbox label:after {
  display: none;
}

.Authorization .radio-description {
  font-size: 15px;
  margin-left: 35px;
  margin-top: 5px;
}

.Authorization .is-pending .loader-tooltip {
  display: inline;
  position: relative;
}

.Authorization .loader-tooltip {
  display: none;
}

.Authorization .ui.loader {
  left: 7px;
  top: 2px;
  float: left;
}

.Authorization .ui.mini.loader:after,
.Authorization .ui.mini.loader:before {
  width: 1.8rem;
  height: 1.8rem;
}

.Authorization .ui.checkbox input.hidden + label,
.Authorization .ui.checkbox input.hidden:focus + label,
.Authorization .ui.checkbox input.hidden:active + label {
  font-weight: 700;
  font-size: 17px;
  padding-left: 35px;
}

@media (max-width: 768px) {
  .Authorization .ui.radio.checkbox {
    margin-bottom: 0px;
  }

  .Authorization .ui.loader {
    top: 8px;
  }
}

.SettingsPageMain {
  position: relative;
}

.SettingsPageMain .dcl.tabs {
  margin-top: 50px;
}

.SettingsPage .left-column {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.SettingsPage .row {
  margin-bottom: 70px;
}

.SettingsPage .dcl.blockie {
  margin-right: 24px;
  float: left;
}

.SettingsPage .address-container {
  margin-top: 7px;
  float: left;
}

.SettingsPage .address {
  margin-bottom: 8px;
  font-size: 21px;
}

.SettingsPage .username-container {
  margin-bottom: 20px;
}

.SettingsPage .username-container .username-title {
  font-family: var(--font-family);
  color: var(--secondary-text);
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  text-transform: uppercase;
}

.SettingsPage .username-container .username-content {
  padding: 20px 20px 20px 20px;
  font-family: "PT Mono";
  color: var(--secondary-text);
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}

.SettingsPage .copy-text,
.SettingsPage .link {
  color: var(--primary);
}

.SettingsPage .link {
  cursor: pointer;
}

.SettingsPage .balance {
  display: flex;
  align-items: center;
}

.SettingsPage .dcl.mana {
  font-size: 21px;
  margin-bottom: 0;
}

.SettingsPage .buy-more {
  margin-left: 24px;
}

.SettingsPage .authorization-checks>label {
  display: block;
  font-size: 17px;
  margin-bottom: 17px;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .SettingsPage .ui.grid>.row {
    flex-direction: column;
    margin-bottom: 48px;
  }

  .SettingsPage .ui.grid>.row>.column:first-child {
    margin-bottom: 16px;
  }

  .SettingsPage .blockie-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .SettingsPage .address-container {
    margin-top: 24px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .SettingsPage .address-container .address {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nft-center {
  position: relative;
  top: 100px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.Title {
  margin-top: 35px;
}

.Title .left .ui.large.header {
  margin-bottom: 0;
  margin-right: 10px;
}

.Title .Column.left .ui.header > .text {
  display: flex;
}

.Title .Badge {
  margin-left: 16px;
  margin-top: 6px;
}

@media (max-width: 768px) {
  .Title .left {
    margin-right: 0px;
    margin-bottom: 24px;
  }
}

.Owner > a {
  display: flex;
  align-items: center;
}

.Owner label {
  text-transform: uppercase;
  color: var(--secondary-text);
  margin-right: 20px;
}

@media (max-width: 768px) {
  .Owner > a {
    flex-direction: column;
    align-items: flex-start;
  }

  .Owner label {
    margin-right: 0px;
    margin-bottom: 12px;
    font-size: 13px;
    line-height: 18px;
  }
}

.ml-15 {
  margin-left: 15px;
}

.update-button {
  background: linear-gradient(#D7B731, #F25F31) !important;
  color: var(--background) !important
}
.dcl.field .CoinField.ui.input input,
.ui.form .dcl.field .CoinField.ui.input input {
  padding-left: 36px !important;
}

.CoinField .dcl.coin.ui.header {
  position: absolute;
  left: 8px;
  bottom: 15px;
  margin-bottom: 0px;
}

@media screen and (max-width: 450px) {
  .CoinField {
    max-width: 400px;
    width: 90% !important;
  }
}

@media screen and (max-width: 410px) {
  .CoinField {
    max-width: 320px;
  }
}

@media screen and (max-width: 360px) {
  .CoinField {
    max-width: 300px;
  }
}

@media screen and (max-width: 345px) {
  .CoinField {
    max-width: 280px;
  }
}
.BreedPriceModal .cancel_listing_button {
  background: linear-gradient(#D7B731, #F25F31) !important;
  color: var(--background)
}

.BreedPriceModal .cancel_listing_button.ui.primary.button {
  color: var(--background)
}

.BreedPriceModal .cancel_listing_button.disabled {
  color: #fff;
}

.BreedPriceModal .cancel_listing_button.ui.primary.button.disabled {
  color: #fff;
}

.WearableDetail .PageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WearableDetail .Highlight .left > div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.horizontal-nft-scroll {
  display: flex;
  flex-direction: row;
  /* max-width: 50%; */
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.horizontal-nft-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.horizontal-nft-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.horizontal-nft-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #101010;
}
.horizontal-nft-scroll::-webkit-scrollbar-track {
  background-color: #313031;
  border-radius: 8px;
}

.related-title-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.related-title-area h6:nth-child(2) {
  cursor: pointer !important;
}

.WearableDetail .rarity {
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px;
  padding: 7px 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: default;
  margin-left: 16px;
  margin-top: 6px;
  cursor: pointer;
}

.WearableDetail .Highlight .body_shape {
  background-image: url(/static/media/BodyShapeIcon.7d2d2181.svg);
}

.WearableDetail .Highlight .earring {
  background-image: url(/static/media/EarringsIcon.b5797b80.svg);
}

.WearableDetail .Highlight .eyebrows {
  background-image: url(/static/media/EyebrowIcon.264226d0.svg);
}

.WearableDetail .Highlight .eyes {
  background-image: url(/static/media/EyesIcon.0bf78f1d.svg);
}

.WearableDetail .Highlight .eyewear {
  background-image: url(/static/media/EyewearIcon.714cfd51.svg);
}

.WearableDetail .Highlight .facial_hair {
  background-image: url(/static/media/FacilHairIcon.1955abf5.svg);
}

.WearableDetail .Highlight .feet {
  background-image: url(/static/media/FeetIcon.88fa8975.svg);
}

.WearableDetail .Highlight .hair {
  background-image: url(/static/media/HairIcon.4f18f427.svg);
}

.WearableDetail .Highlight .hat {
  background-image: url(/static/media/HatIcon.0ec3953a.svg);
}

.WearableDetail .Highlight .helmet {
  background-image: url(/static/media/HelmetIcon.dd7b36f0.svg);
}

.WearableDetail .Highlight .lower_body {
  background-image: url(/static/media/LowerBodyIcon.9ae60b69.svg);
}

.WearableDetail .Highlight .mask {
  background-image: url(/static/media/MaskIcon.6a35beb3.svg);
}

.WearableDetail .Highlight .mouth {
  background-image: url(/static/media/MouthIcon.26f25edc.svg);
}

.WearableDetail .Highlight .tiara {
  background-image: url(/static/media/TiaraIcon.3c31fa49.svg);
}

.WearableDetail .Highlight .top_head {
  background-image: url(/static/media/TopHeadIcon.1e7c50a5.svg);
}

.WearableDetail .Highlight .upper_body {
  background-image: url(/static/media/UpperBodyIcon.008bdffa.svg);
}

.WearableDetail .Highlight .left > .BaseMale {
  background-image: url(/static/media/MaleIcon.bcbdebfe.svg);
  background-size: 24px 24px;
}

.WearableDetail .Highlight .left > .BaseFemale {
  background-image: url(/static/media/FemaleIcon.eefd8bbb.svg);
  background-size: 24px 24px;
}

.WearableDetail .Highlight .left > .Unisex {
  background-image: url(/static/media/UnisexIcon.049c756e.svg);
  background-size: 24px 24px;
}

.product-container {
  margin-top: 133px;
}

@media (max-width: 768px) {
  .product-container {
    margin-top: 20px;
    padding-left: 0;
  }
}

.price-dropdown {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.price-dropdown > .text {
  color: #676370 !important;
  font-size: 12px !important;
  font-family: Poppins;
  text-transform: none !important;
  height: 20px;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
}

.price-dropdown > .dropdown.icon {
  color: #676370 !important;
}

.price-dropdown > .text::before {
  content: url(/static/media/calendar.fa0c0eed.svg);
  margin-right: 7px;
}

.next-breeding {
  width: 720px;
  margin-top: 20px;
}

.progress-last-bred-box {
  font-size: 15px;
  font-family: var(--font-family);
  color: rgb(255, 99, 132);
}

.progress-last-bred {
  font-size: 12px;
  font-family: var(--font-family);
  color: rgb(255, 99, 132);
}

.progress-can-breed-box {
  font-size: 15px;
  font-family: var(--font-family);
  color: rgb(54, 162, 235);
}

.progress-can-breed {
  font-size: 12px;
  font-family: var(--font-family);
  color: rgb(54, 162, 235);
}

.next-breeding-progress .bar .progress {
  top: 150% !important;
  right: -5em !important;
  color: rgb(217, 225, 160);
}

.next-breeding-progress .bar .progress-low {
  white-space: nowrap;
  position: absolute;
  width: auto;
  font-size: 0.92857143em;
  top: 150%;
  right: -7em;
  left: auto;
  bottom: auto;
  color: rgb(217, 225, 160);
  text-shadow: none;
  margin-top: -0.5em;
  font-weight: 700;
  text-align: left;
}

.in-game-status-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px;
  background: linear-gradient(0deg, #0f0f0f, #0f0f0f),
    linear-gradient(0deg, #2a2a2a, #2a2a2a);
  height: 59px;
  border-radius: 8px;
  border: 1px solid #2a2a2a;
}

.in-game-status-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.in-game-status-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}

.in-game-status-box {
  position: absolute;
  height: 16px;
  width: 82px;
  left: 109px;
  top: 50px;
  border-radius: 10px;
  background: #2a2a2a;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.in-game-status-box-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  /* line-height: 12px; */
  /* identical to box height, or 150% */

  text-transform: uppercase;

  /* Light */

  color: #ebebeb;
}

@media (max-width: 1201px) {
  .next-breeding {
    width: 660px;
  }
}

@media (max-width: 991px) {
  .next-breeding {
    width: 435px;
  }
}

@media (max-width: 768px) {
  .next-breeding {
    width: 100%;
  }

  .block-container {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .next-breeding {
    width: 358px;
  }
}

@media (max-width: 375px) {
  .next-breeding {
    width: 350px;
  }
}

@media (max-width: 320px) {
  .next-breeding {
    width: 290px;
  }
}

.kmon-detail-container {
  padding-bottom: 300px;
}

.position-relative {
  position: absolute;
  width: 100vw;
  left: 0;
}

.homepagedata .position-relative {
  position: relative;
  width: 100vw;
  left: 0;
}

.kmon-detail-bottom-bg {
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 400px;
  position: absolute;
}

.homepagedata .kmon-detail-bottom-bg {
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 400px;
  position: relative;
}

.kmon-detail-bottom-bg .kmon-detail-bottom-bg-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(/static/media/bottom-bg.c47c88f4.jpg);
  background-size: cover;
  height: 100%;
  background-position: center;
}

.kmon-detail-bottom-wave-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(/static/media/Blue_waves.53b73c26.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  margin-bottom: -1%;
}

.NFTCard {
  overflow: hidden;
}

.NFTCard .header,
.NFTCard .dcl.mana.inline {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.2px;
}

.NFTCard .header .title {
  flex: 1 2 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 20px;
}

.ui.card.NFTCard > .content {
  flex: none;
}

.ui.card.NFTCard > .content > .header:not(.ui),
.ui.cards > .card.NFTCard > .content > .header:not(.ui) {
  display: flex;
  margin-bottom: 6px;
}

.NFTCard .dcl.mana.inline {
  margin: 0px;
  flex: none;
}

.ui.cards > .ui.card.NFTCard.link .meta,
.ui.card.link .meta {
  margin-bottom: 4px;
}

.ui.cards > .ui.card.NFTCard.link:hover .meta,
a.ui.card.link:hover .meta {
  color: var(--secondary-text);
}
.ui.cards > .ui.card,
.ui.card {
  background-color: #323132 !important;
  border: none !important;
}
.ui.card:hover {
  border: none !important;
}
.NFTCard .tags {
  margin-top: 12px;
}

.NFTCard .NFTImage {
  overflow: hidden;
}

.NFTCard .NFTImage .ens-subdomain {
  background-size: 557px;
  background-position-y: -133px;
}

.NFTCard .NFTImage .ens-subdomain .monospace {
  margin-top: 0px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .NFTCard .header {
    display: flex;
    flex-direction: column;
  }

  .NFTCard .header .title {
    margin-right: 0;
  }

  .NFTCard .dcl.mana.inline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
}

@media (max-width: 768px) {
  .NFTCard.ui.card > .content,
  .ui.cards > .NFTCard.card > .content {
    display: block;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #323132;
  box-shadow: none;
  width: 272px;
  border-radius: 8px;
  overflow: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: flex-end;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    margin-right: 0px;
  }
}

.card-footer {
  background-color: #e7e3d7;
}

.card-image-container {
  width: 100%;
  height: 302px;
  position: relative;
}

.card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2d2e;
}

.card-image-text {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 16px;
}

.card-image-text-details {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 16px;
}

.product-type-icon {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-image: url(/static/media/elem-fire.e1d0696b.svg);
  display: flex;
  align-self: flex-end;
}

.product-type-icon-details {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-image: url(/static/media/elem-fire.e1d0696b.svg);
  display: flex;
  align-self: flex-start;
}
.product-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.product-info-value {
  font-size: 10px;
  margin: 0;
}
.product-info-number {
  width: 60%;
  margin: 0 !important;
  font-size: 17px;
  margin-bottom: 12px;
  font-family: var(--font-family);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
}

.product-verified {
  width: 18px;
  height: 16px;
  background: url(/static/media/certified-badge.4b562c1b.svg) center center no-repeat;
  margin: 4px;
  display: none;
}

.product-edit {
  width: 18px;
  height: 18px;
  background: url(/static/media/edit.ec7061a2.svg) center center no-repeat;
  margin: 4px;
  cursor: pointer;
}

.product-title {
  color: black;
}

.product-description {
  display: flex;
  justify-content: space-between;
  background-color: #e7e3d7;
  flex-direction: row;
  width: 100%;
  padding: 11px 10px;
}

.product-description-left {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.product-description-left .Row {
  align-items: center;
  width: auto;
}

.product-description-left-item {
  color: #000000;
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
}

.product-description-mid-heart {
  width: 40px;
  height: 36px;
  background-image: url(/static/media/heart.412ed799.svg);
}

.product-description-mid-heart-empty {
  width: 40px;
  height: 36px;
}

.product-description-right {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 8px;
  margin-bottom: 5px;
}

.card-container {
  width: 272px;
  height: 400px;
  border-radius: 8px;
}

.card-image-text-details > .ui.toggle.checkbox input.hidden + label:after {
  background-color: var(--primary) !important;
}

.breeding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 272px;
}

.breeding-box {
  background-color: #323132;
  padding: 12px;
  padding-top: 18px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1201px) {
  .top-element {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .breeding-container {
    width: 100%;
  }
  .top-element {
    width: 100%;
  }
}

.cooldown-time {
  font-size: 10px;
  color: #ddd;
  margin: 0;
}

.set-price-text {
  font-size: 12px;
  color: rgb(180, 42, 120);
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}

.set-price-text:hover {
  text-shadow: 0 0 3px #fff9, 0 0 5px #00f4;
}

.margin-vertical {
  margin: 10px 0;
}

.block-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-family: var(--font-poppins);
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0;
}

.title-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
}

.title-icon {
  width: 35px;
  height: 35px;
  margin-right: 1em;
}

.details-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  /* width: calc(100% - 272px); */
  width: 100%;
}

.details-container .button {
  margin-left: 15px!important;
}

@media (max-width: 768px) {
  .details-container {
    margin: 16px 0;
    flex-direction: column;
  }

  .details-container .button {
    margin-left: 0px!important;
  }
}
.profile-container {
  display: flex;
  flex-direction: row;
}

.block-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 42px;
}

@media (max-width: 1201px) {
  .block-title {
    padding-left: 12px;
  }
}

.block-title-text {
  font-family: var(--font-monospace);
  font-size: 10px;
  color: #676370;
  text-transform: uppercase;
}

.detail-nft-name {
  color: #eb2f96;
  font-family: var(--font-family);
  font-size: 15px;
}

.detail-medium-text {
  font-family: var(--font-monospace);
  font-size: 12px;
  font-weight: 700;
  color: #e7e3d7;
  max-width: 100px;
  overflow: hidden;
}

.detail-big-text {
  font-family: var(--font-monospace);
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.dna-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  border-radius: 8px;
  position: relative;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .dna-container {
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* @media (max-width: 1201px) {
  .dna-container {
    width: 554px;
    left: 30px;
  }
} */

/* @media (max-width: 991px) {
  .dna-container {
    width: 435px;
    height: 270px;
    padding: 25px 5px 5px 5px;
  }
} */

/* @media (max-width: 768px) {
  .dna-container {
    width: 90%;
    left: 36px;
    top: 85px;
  }
} */

/* @media (max-width: 650px) {
  .dna-container {
    width: 85%;
    padding: 25px 5px 5px 5px;
    left: 43px;
    top: 63px;
  }
} */

/* @media (max-width: 480px) {
  .dna-container {
    width: 88%;
    left: 18px;
    top: 63px;
  }
} */

/* @media (max-width: 425px) {
  .dna-container {
    width: 72%;
    left: 41px;
    top: 50px;
  }
} */

/* @media (max-width: 375px) {
  .dna-container {
    width: 76%;
    left: 34px;
    top: 50px;
  }
} */
/* @media (max-width: 425px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
}

@media (max-width: 375px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
}

@media (max-width: 320px) {
  .dna-container {
    padding: 25px 5px 5px 5px;
    height: auto;
  }
} */

.dna-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .dna-info {
    top: 10px;
    right: 10px;
  }
}

.dna-info-generation {
  padding: 0.5px 10px;
  background-color: #39c876;
  border-radius: 10px;
  font-size: 10px;
  font-family: var(--font-monospace);
  font-weight: 700;
  color: #232123;
}

.dna-info-start {
  margin-left: 9px;
}

.meta-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .meta-container {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.top-meta-row-text {
  color: #ddd;
  font-family: var(--font-family);
  padding-right: 8px;
}

.small-row-text {
  color: #ddd;
  font-family: var(--font-family);
  font-size: 10px;
}

.elem-row-value {
  color: #fff;
  font-family: var(--font-family);
}

.stats-text {
  font-size: 14px;
}

.general-stats {
  width: 100%;
  max-width: 280px;
  margin-bottom: 2em;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
}

@media (max-width: 991px) {
  .general-stats {
    max-width: 370px;
  }
}

.flex-direction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stats-item:not(:last-child) {
  border-bottom: 1px solid #222;
  width: 100%;
}

.top-meta-img-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.top-meta-text-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.top-meta-text-group p {
  margin: 0 !important;
}

.top-meta-img-title img {
  margin: 1em;
}

.stats-description-text {
  font-size: 14px;
  color: #888;
  font-family: 'PT Mono', sans-serif;
}

.value-text {
  font-size: 16px;
  color: rgb(243, 186, 47);
  margin: 0 8px;
}

.meta-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .meta-container {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.meta-row-text {
  color: #ddd;
  font-family: var(--font-family);
  padding-right: 8px;
}

.small-row-text {
  color: #ddd;
  font-family: var(--font-family);
  font-size: 10px;
}

.elem-row-value {
  color: #fff;
  font-family: var(--font-family);
}

.stats-text {
  font-size: 14px;
}

.general-stats {
  /* width: 100%; */
  margin-bottom: 2em;
  -webkit-margin-end: auto;
          margin-inline-end: auto;
}


.bottom-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

@media (max-width: 991px) {
  .bottom-container{
    flex-direction: column;
    justify-content: space-around;
  }
  .bottom-data {
    margin-top: 70px;
  }
}

.flex-direction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.elementgens {
  display: flex;
  flex-direction: column;
}

.elementgens h6{
  margin-top: 0px;
  margin-bottom: 0px;
}

.elementgens p{
  margin-top: 0px;
  margin-bottom: 0px;
}

.stats-item {
  width: 100%;
}

.stats-item:not(:last-child) {
  border-bottom: 1px solid #222;
}

.bottom-meta-img-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.stats-icon {
  margin: 1em;
}

.item-left{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stats-description-text {
  font-size: 14px;
  color: #888;
}

.value-text {
  font-size: 16px;
  color: rgb(243, 186, 47);
  margin: 0 8px;
}

.elem-power-container {
  width: 100%;
  border-radius: 10px;  
  overflow: hidden;
}

.elem-power-item {
  background-color: #0E0E0E;
  padding: 4px 15px 4px 4px;
  border-bottom: 2px solid black;
}

@media (min-width: 768px) {
  .elem-power-container {
    max-width: 272px;
  }
}

@media (max-width: 768px) {
  .meta-container {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.meta-row-text {
  color: #ddd;
  font-family: var(--font-family);
  padding-right: 8px;
}
.elem-title {
  font-family: var(--font-family);
  margin: 0 5px 0 0;
}

.small-row-text {
  color: #ddd;
  font-family: var(--font-family);
  font-size: 10px;
}
.elem-power-row-text {
  color: #ddd;
  font-family: var(--font-family);
  font-size: 10px;
  margin: 0 15px 0 0;
  padding: 0;
}

.elem-power-percent {
  font-family: var(--font-family);
  height: 40px;
}

.elem-row-value {
  color: #fff;
  font-family: var(--font-family);
}

.stats-text {
  font-size: 14px;
}

.general-stats {
  width: 100%;
  max-width: 280px;
  margin-bottom: 2em;
}

@media (max-width: 991px) {
  .general-stats {
    max-width: 370px;
  }
}

.flex-direction-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.elem-power-display-flex {
  display: flex;
}

.stats-item {
  border-bottom: 1px solid #222;
  width: 100%;
}

.img-title {
  display: flex;
  align-items: center;
}

.img-title img {
  margin: 1em;
}

.stats-description-text {
  font-size: 14px;
  color: #888;
}

.elemental-power-value-text {
  font-size: 16px;
  color: rgb(243, 186, 47);
  margin: 0 8px 0 0;
}

.elems-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #323132;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-subcontainer {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #323132;
  border-radius: 30px;
  position: relative;
  width: 45%;
  max-width: 738px;
  flex-wrap: wrap;
}

.gamedata-title {
  margin-left: 10px;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}

.gamedata-subcontainer .elem-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 20px 5px 10px;
}

@media (max-width: 992px) {
  .elem-row {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .elems-container {
    max-width: 100%;
  }
  .elem-row {
    width: 33%;
  }
  .gamedata-subcontainer {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 500px) {
  .elem-row {
    width: 50%;
  }
}
.game-row-text {
  color: #3898FF;
  font-family: var(--font-family);
  padding-right: 8px;
}

.game-row-value {
  color: #fff;
  font-family: var(--font-family);
}

.NFTPage .NFTImage .pixel {
  display: none;
}

.NFTPage .NFTImage .ens-subdomain {
  font-size: 64px;
  background-size: 2016px;
}

.NFTPage .NFTImage .ens-subdomain .name {
  padding: 25px;
}

.NFTPage .ui.container > div {
  margin-bottom: 48px;
}

@media (max-width: 768px) {
  .NFTPage .ui.container > .Row {
    flex-direction: column;
  }

  .NFTPage .ui.container > .Row .dcl.stats,
  .NFTPage .ui.container > .Row .ui.button {
    width: 100%;
  }

  .NFTPage .ui.container > .Row .ui.button {
    margin-left: 0px;
    margin-top: 24px;
  }
}

.wallet-center {
  position: relative;
  top: 100px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 350px);
}

.NFTAction .back {
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.NFTAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.NFTAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 80px;
}

.NFTAction .Row .right {
  flex: 1.25 1 auto;
}

.NFTAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.NFTAction .Row .payment #simplex-form #checkout-element > iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.NFTAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.NFTAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.NFTAction .Row .payment > div > iframe {
  pointer-events: all;
}

.NFTAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.NFTAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 768px) {
  .NFTAction > .Row {
    flex-direction: column;
  }

  .NFTAction > .Row .Column.left,
  .NFTAction > .Row .nft-image-wrapper,
  .NFTAction > .Row .ui.button {
    width: 100%;
  }

  .NFTAction > .Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .NFTAction > .Row .nft-image-wrapper,
  .NFTAction > .Row .ui.button + .ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .NFTAction .Row .payment #simplex-form #checkout-element > iframe {
    width: auto;
    padding: 25px 10px;
  }
}

.SellPage .ui.header {
  margin-bottom: 8px;
}

.SellPage .subtitle {
  margin-bottom: 32px;
}

.SellPage .form-fields {
  max-width: 420px;
  margin-bottom: 20px;
}

.ConfirmPriceModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .SellPage .dcl.field,
  .ConfirmPriceModal .dcl.field {
    width: 100%;
    min-width: auto;
  }
}

.BuyPage .ui.header {
  margin-bottom: 8px;
}

.BuyPage .buttons {
  margin-top: 32px;
}

.BuyPage .error {
  color: var(--danger);
}

.BidPage .ui.header {
  margin-bottom: 8px;
}

.BidPage .subtitle {
  margin-bottom: 32px;
}

.BidPage .form-fields {
  max-width: 420px;
  margin-bottom: 20px;
}

.BidPage .fields .dcl.field {
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .BidPage .dcl.field {
    min-width: auto;
  }
}


.CancelSalePage .ui.header {
  margin-bottom: 8px;
}

.CancelSalePage .subtitle {
  margin-bottom: 32px;
}

.TransferPage .ui.header {
  margin-bottom: 8px;
}

.TransferPage .subtitle {
  margin-bottom: 32px;
}

.TransferPage .subtitle.error {
  color: var(--danger);
}

.TransferPage .warning {
  color: var(--danger);
  margin-bottom: 24px;
}

.TransferPage .message {
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 10px;
}

.TransferPage .form-fields {
  width: 420px;
}

@media only screen and (max-width: 767px){
  .TransferPage .form-fields {
    width: 100%;
  }
  .TransferPage .form-fields .dcl.field{
    min-width: auto;
    margin-top: 20px;
  }
}
.LootboxCard .product-type-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;
  background: #6331f2;
  border-radius: 50px;
}

.lootbox-detail-card .card-image .image-wrapper img {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%) scale(0.9);
          transform: translate(50%, -50%) scale(0.9);
}

.lootbox-detail-card .card-image-text.candy {
  align-items: flex-end;
}

.ui.cards > .ui.card.link,
.ui.card.link.lootbox-detail-card {
  box-shadow: var(--shadow-2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
}

.lootbox-detail .ui.card.link.lootbox-detail-card {
  height: 100%;
}

.lootbox-detail-card .card-image-text.candy {
  align-items: flex-end;
}

.lootbox-detail-card .product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.lootbox-detail-card .product-type-price-candy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.lootbox-detail-card .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.lootbox-detail-card .product-info-number-card {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lootbox-detail-card .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.lootbox-detail-card .product-description-left-item {
  color: #000000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.lootbox-detail-card .product-description-right-nft {
  background-color: #6331f2;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex;
}

.lootbox-detail-card .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

.TransferPage .ui.header {
  margin-bottom: 8px;
}

.TransferPage .subtitle {
  margin-bottom: 32px;
}

.TransferPage .subtitle.error {
  color: var(--danger);
}

.TransferPage .warning {
  color: var(--danger);
  margin-bottom: 24px;
}

.TransferPage .message {
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 10px;
}

.TransferPage .form-fields {
  width: 420px;
}

.TransferPage .buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 12px;
}

.TransferPage{
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .TransferPage .form-fields {
    width: 100%;
  }

  .TransferPage .form-fields .dcl.field {
    min-width: auto;
    margin-top: 20px;
  }
}

.NFTAction .back {
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.NFTAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.NFTAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 80px;
}

.NFTAction .Row .right {
  flex: 1.25 1 auto;
}

.NFTAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.NFTAction .Row .payment #simplex-form #checkout-element>iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.NFTAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.NFTAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.NFTAction .Row .payment>div>iframe {
  pointer-events: all;
}

.NFTAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.NFTAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 768px) {
  .NFTAction>.Row {
    flex-direction: column;
  }

  .NFTAction>.Row .Column.left,
  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button {
    width: 100%;
  }

  .NFTAction>.Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button+.ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .NFTAction .Row .payment #simplex-form #checkout-element>iframe {
    width: auto;
    padding: 25px 10px;
  }
}

.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.lootbox-detail .lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}
.SellPage .ui.header {
  margin-bottom: 8px;
}

.SellPage .subtitle {
  margin-bottom: 32px;
}

.SellPage .subtitle.error {
  color: var(--danger);
}

.SellPage .warning {
  color: var(--danger);
  margin-bottom: 24px;
}

.SellPage .message {
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 10px;
}

.SellPage .form-fields {
  width: 420px;
}

.SellPage .buttons {
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 12px;
}


@media only screen and (max-width: 767px) {
  .SellPage .form-fields {
    width: 100%;
  }

  .SellPage .form-fields .dcl.field {
    min-width: auto;
    margin-top: 20px;
  }
}

.NFTAction .back {
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.NFTAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.NFTAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 40px;
}

.NFTAction .Row .right {
  flex: 1.25 1 auto;
}

.NFTAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.NFTAction .Row .payment #simplex-form #checkout-element>iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.NFTAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.NFTAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.NFTAction .Row .payment>div>iframe {
  pointer-events: all;
}

.NFTAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.NFTAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 768px) {
  .NFTAction>.Row {
    flex-direction: column;
  }

  .NFTAction>.Row .Column.left,
  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button {
    width: 100%;
  }

  .NFTAction>.Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button+.ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .NFTAction .Row .payment #simplex-form #checkout-element>iframe {
    width: auto;
    padding: 25px 10px;
  }
}

.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-bottom: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}
.ConfirmNumberOfItemsModal .ModalAction {
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin-left: 10px;
}
.TransactionDetail {
  margin-bottom: 24px;
}

.TransactionDetail .left {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.TransactionDetail .right {
  display: flex;
  align-items: center;
  flex: none;
  margin-left: 16px;
}

.TransactionDetail .left div.image {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  flex: none;
  margin-right: 16px;
}

.TransactionDetail .left .image .dcl.mana {
  font-size: 48px;
  height: 48px;
  padding-top: 10px;
  margin-left: 2px;
  margin-top: -2px;
  display: flex;
}

.TransactionDetail .left .image .dcl.mana .matic {
  width: 41px;
  background-repeat: no-repeat;
  margin-top: -9px;
  margin-left: 1px;
}

.TransactionDetail .left .text {
  display: flex;
  align-items: baseline;
  overflow: hidden;
}

.TransactionDetail .left .text .description {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TransactionDetail .left .description .dcl.blockie {
  margin-left: 3px;
}

.TransactionDetail .left .text .timestamp {
  color: var(--secondary-text);
  margin-left: 8px;
}

.TransactionDetail .right .status {
  display: flex;
  color: var(--secondary-text);
}

.TransactionDetail .right .status .description {
  text-transform: capitalize;
}

.TransactionDetail .right .status .description + * {
  margin-left: 8px;
}

.TransactionDetail .right .status.confirmed,
.TransactionDetail .right .status.replaced {
  color: var(--neon-blue);
}

.TransactionDetail .right .status.reverted {
  color: var(--danger);
}

.TransactionDetail .right .status .spinner {
  position: relative;
  width: 20px;
  height: 16px;
  margin-top: 3px;
}

@media (max-width: 768px) {
  .TransactionDetail .right .status .description,
  .TransactionDetail .left .text .timestamp {
    display: none;
  }
}

.ActivityPageMain {
  position: relative;
}

.ActivityPageMain .dcl.tabs {
  margin-top: 50px;
}

.ActivityPage .center {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityPage .ui.basic.button {
  padding: 0;
}

.ActivityPage .center p {
  color: var(--secondary-text);
}

.ActivityPage .transactions {
  margin-top: 20px;
}
.Slideshow {
  margin-bottom: 70px;
  position: relative;
  width: 99%;
}

/* shadow */
.Slideshow:after {
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 30%,
    rgba(15, 15, 15, 0) 80%,
    rgba(15, 15, 15, 0)
  );
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: -30px;
  width: 220px;
}

@media (max-width: 768px) {
  .Slideshow:after {
    width: auto;
  }

  .Slideshow .dcl.header-menu {
    padding-left: 20px;
  }

  .Slideshow .nfts {
    padding-left: 20px;
  }
}

.Slideshow .nfts {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  position: relative;
  min-height: 336px;
}

.Slideshow .nfts .NFTCard,
.Slideshow .nfts .ui.card.NFTCard:first-child {
  flex: 0 0 auto;
  width: 220px;
  margin-right: 20px;
  height: 280px;
  margin-top: 12px;
}

.Slideshow .dcl.header-menu {
  margin-bottom: -23px;
}

.Slideshow i.caret {
  background-image: url(/static/media/forward.ce80e0ce.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 13px;
  margin-left: 4px;
}

.Slideshow .ui.button.basic {
  display: flex;
  align-items: center;
}

.Slideshow .ui.button.basic,
.Slideshow .ui.button.basic:hover {
  color: var(--text) !important;
  font-family: var(--font-poppins);
}

.Slideshow .header-menu-right {
  z-index: 1;
}

.community-container {
    width: 100%;
    max-width: 1300px;
    justify-content: center;
}

.community-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.community-title p {
    font-family: var(--font-family);
    font-size: 70px;
    letter-spacing: -1.56px;
    line-height: 65px;
    margin: 0 8px;
}

@media (max-width: 960px) {
    .community-title p {
        font-size: 50px;
        letter-spacing: -1.56px;
        line-height: 55px;
        margin: 0 8px;
    }
}

.community-amount {
    color: #F3BB2B;
}

.community-row {
    display: flex;
}

.card-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3em;
}

.community-card {
    background-color: #1E1B1C;
    border-radius: 10px;
    width: 22%;
    height: 120px;
    margin: 1% 1.5%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    cursor: pointer;
}

.community-card p {
    font-family: 'PT Mono', sans-serif;
}

.community-followers {
    font-size: 18px;
    text-align: center;
}

@media (max-width: 960px) {
    .community-followers {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .community-card {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .community-card {
        width: 45%;
    }
    .community-title p {
        font-size: 30px;
        letter-spacing: -1.56px;
        line-height: 45px;
        margin: 0 8px;
    }
}
.HomePage .dcl.tabs {
  text-align: center;
  font-family: var(--font-poppins);
  border: 0px;
  margin-top: 25px;
}

.HomePage .dcl.page {
  min-height: auto;
  width: 100%;
}

.dcl.navbar .ui.container {
  align-items: center !important;
}

.homepage-horizontal-nft-scroll{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  min-height: 300px;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.homepage-horizontal-nft-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #101010;
}
.homepage-horizontal-nft-scroll::-webkit-scrollbar-track { 
  background-color: #313031; 
  border-radius: 8px; 
}

@media only screen and (min-width: 768px) {
  .HomePage .PageCustomHeader .dcl.tabs {
    display: none;
  }
}

.dcl.hero.centered {
  align-items: center;
  border: 0px;
}

.HomePageHero.dcl.hero {
  height: var(--page-header-height);
  padding: 64px 64px 30px;
  margin-bottom: 0px;
}

.HomePageHero {
  display: flex;
  align-items: center;
}

.HomePageHero .hero-title-text {
  font-family: var(--font-family);
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 15;
  font-size: 70px;
  color: #e7e3d7;
  letter-spacing: -1.56px;
  line-height: 65px;
  width: 648px;
  margin-top: -332px;
}

.dcl.header-menu .ui.header {
  margin-bottom: 0px;
  font-family: var(--font-poppins) !important;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1064px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.container {
    width: 953px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.dcl.hero .hero-content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.HomePageHero .hero-actions .button-secondary {
  background-image: linear-gradient(180deg, #d7b731 0%, #f25f31 100%) !important;
  color: #232123 !important;
  margin-top: 100px;
  text-transform: uppercase !important;
}

.hero-logo {
  background-image: url(/static/media/kryptomon-logo.db08af7b.svg);
  position: absolute;
  height: 140px;
  width: 343px;
  margin-top: 69px;
  background-size: 362px 122px;
}

.hero-market {
  background-image: url(/static/media/kryptomon-market.aadc2596.svg);
  position: absolute;
  height: 120px;
  width: 301px;
  margin-top: 340px;
  background-size: 300px 120px;
  z-index: 19;
}

.hero-fade {
  background-image: url(/static/media/kryptomon-fadeMount.757f2287.png);
  position: absolute;
  top: 212px;
  left: auto;
  bottom: 0px;
  right: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0px auto -25px;
  width: 116vw;
}

.HomePageHero .hero-image {
  position: absolute;
  top: 0px;
  left: auto;
  bottom: 0px;
  right: auto;
  background-image: url(/static/media/kryptomon-hero.10039304.jpg);
  opacity: 0.4;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -2;
  background-position: center;
  margin: -345px auto 0px;
  min-width: 1664px;
  width: 100vw;
}

.HomePageContent .flash-container {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.HomePageContent .flash-container .play-game .button-play {
  margin-top: 10px;
  height: 38px;
  font-size: 16px;
}

.HomePageContent .play-game {
  /* position: absolute; */
  background: url(/static/media/Play_Game.acc4c73c.jpg) no-repeat center;
  background-size: 100%;
  width: 40%;
  height: 168px;
  z-index: 100;
  border-radius: 10px;
  margin-right: 20px;
  padding: 40px;
  padding-top: 30px;
}

.HomePageContent .flash-container .become-trainer {
  width: 50%;
  line-height: 30px;
  font-family: var(--font-family);
  font-size: 30px;
  color: #e7e3d7;
}

.HomePageContent .flash {
  /* position: absolute; */
  background: url(/static/media/Flash.39e71f8e.jpg) no-repeat center;
  background-size: 100%;
  width: 40%;
  height: 168px;
  z-index: 100;
  border-radius: 10px;
  margin-left: 20px;
  text-align: center;
  position: relative;
}

.HomePageContent .flash-container .starting-date {
  font-family: 'Poppins';
  font-size: 15px;
  color: #F3BA2F;
  position: absolute;
  bottom: 30px;
  left: 35%;
}

.HomePageHero .grid-top {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/grid_top.bc4b39ca.png);
  opacity: 0.5;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.HomePageHero .dragons {
  position: absolute;
  top: 36%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 525px;
  height: 60%;
  background-image: url(/static/media/dragons.e42c3666.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  z-index: -1;
}

.HomePageHero.dcl.hero .ui.header.hero-subtitle {
  font-family: inherit;
  margin-bottom: 24px;
  color: var(--secondary-text);
  max-width: 55%;
}

.HomePage .dcl.tabs {
  font-family: var(--font-poppins);
}

.HomePageContent {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: -296px;
}

.HomePageContent .bottom-bg {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
}

.HomePageContent .bottom-bg .bottom-bg-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(/static/media/bottom-bg.c47c88f4.jpg);
  background-size: cover;
  height: 50%;
  background-position: center;
  margin: 0px auto;
}

.bottom-wave-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(/static/media/Blue_waves.53b73c26.png);
  background-size: cover;
  height: 30%;
  background-position: center;
  margin: 0px auto;
}

.HomePageContent .Footer {
  bottom: 0;
}

.HomePage-carousels-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.HomePage-carousels-header-main{
  width: 45%;
}

.HomePage-carousels-header-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
}

.HomePage-carousels-header-text div{
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-poppins);
}

.HomePage-carousels-header-text p{
  font-family: var(--font-poppins);
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
}

@media (min-width: 1440px) {
  .HomePageHero.dcl.hero {
    height: 1147px;
    border: 0px;
  }

  .HomePageContent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: -448px;
  }
}

@media (max-width: 1440px) {
  .HomePageHero.dcl.hero {
    height: 988px;
  }

  .hero-fade {
    top: -73px;
  }

  .hero-logo {
    margin-top: 30px;
  }

  .hero-market {
    margin-top: 258px;
  }

  .HomePageHero .hero-image {
    margin: -611px auto 0px;
    min-width: 1442px;
    width: 100vw;
  }

  .HomePageContent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: -480px;
  }
}

@media (max-width: 919px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 60px;
    font-weight: 400;
    line-height: 55px;
    display: flex;
    justify-content: center;
    letter-spacing: normal;
  }

  .HomePageHero .hero-image {
    margin: -795px auto 0px;
    min-width: 1150px;
  }

  .hero-fade {
    top: -456px;
    width: 1000px;
    background-size: contain;
  }

  .HomePageHero .dragons {
    position: absolute;
    top: 33%;
    width: 380px;
  }
}

@media (max-width: 768px) {
  .HomePageHero.dcl.hero .ui.header.hero-subtitle {
    margin-top: 20px;
    max-width: 100%;
  }

  .HomePageContent .dcl.tabs {
    display: none;
  }
  
  .HomePage-carousels-header-main{
    width: 100%;
  }

  .homepage-horizontal-nft-scroll{
    width: 100%;
  }

  .HomePage-carousels-header{
    display: flex;
    flex-direction: column;
  }  

  .Slideshow .nfts {
    display: grid;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 92vw;
    grid-auto-flow: column;
  }

  .HomePageContent .flash-container {
    flex-direction: column;
  }

  .HomePageContent .play-game {
    width: 100%;
    border-radius: 30px;
    margin-right: 0px;
  }

  .HomePageContent .flash {
    width: 100%;
    border-radius: 30px;
    margin-left: 0px;
    margin-top: 20px;
  }

  .HomePageContent .flash-container .become-trainer {
    width: 50%;
    line-height: 30px;
    font-family: var(--font-family);
    font-size: 20px;
    color: #e7e3d7;
  }

  .HomePageContent .flash-container .play-game .button-play {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 55px;
    line-height: 50px;
    display: flex;
    justify-content: center;
  }

  .hero-logo {
    margin-top: 75px;
    position: absolute;
    height: 75px;
    width: 329px;
    margin-top: 69px;
    background-size: 329px 80px;
  }

  .hero-market {
    position: absolute;
    height: 110px;
    width: 250px;
    margin-top: 255px;
    background-size: 260px 105px;
  }

  .dcl.navbar .ui.container {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start !important;
    z-index: 10000;
  }

  .kryptomon.logo {
    width: 160px !important;
    height: 64px !important;
    margin-right: 0px !important;
  }

  .marketplace-sub-logo {
    width: 72px !important;
    height: 36px !important;
  }

  .dcl.user-menu-wrapper .activity-bell.item {
    /* display: none; */
  }

  .dcl.account-wrapper {
    /* display: none !important; */
  }

  .dcl.navbar-mobile-search {
    margin-left: 10px;
    margin-right: 10px;
  }

  .dcl.tabs.fullscreen {
    position: absolute;
    top: 0px;
    z-index: 999;
    margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
    margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
    width: 50% !important;
  }

  .dcl.tabs {
    position: absolute;
    top: 0px;
    z-index: 999;
    margin-top: calc(var(--navbar-margin-bottom) / 2) !important;
    margin-bottom: calc(var(--navbar-margin-bottom) / 2) !important;
    width: 50% !important;
    /* margin-top: 0px !important; */
  }

  .dcl.tabs-left.show {
    background-color: #000;
  }
}

@media (max-width: 540px) {
  .hero-market {
    margin-top: 280px;
  }
}

@media (max-width: 480px) {
  .hero-logo {
    margin-top: 108px;
  }

  .HomePageHero .dragons {
    position: absolute;
    top: 37%;
    width: 328px;
  }

  .hero-market {
    margin-top: 272px;
    background-size: 225px 90px;
    height: 98px;
  }

  .HomePageContent .play-game {
    width: 100%;
    height: 168px;
  }

  .HomePageContent .flash {
    width: 100%;
    height: 168px;
  }
}

@media (max-width: 425px) {
  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 335px;
  }
}

@media (max-width: 479px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 40px;
    line-height: 37px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 320px;
  }
}

@media (max-width: 320px) {
  .HomePageHero .hero-title-text {
    width: 100%;
    font-size: 40px;
    line-height: 37px;
    display: flex;
    justify-content: center;
  }

  .Slideshow .nfts {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    position: relative;
    overflow-x: scroll;
    min-height: 336px;
    white-space: nowrap;
    width: 290px;
  }
}
.WarningMessage {
  padding: 16px;
  color: var(--danger);
  text-align: center;
  border-top: 1px solid var(--divider);
}

.Bid {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--card);
  margin-bottom: 20px;
}

.Bid .bid-row {
  display: flex;
}

.Bid .image {
  flex: none;
  height: 96px;
  width: 96px;
  position: relative;
}

.Bid .wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.Bid .wrapper .info {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Bid .wrapper .info .dcl.stats {
  width: 20%;
}

.Bid .wrapper .info .dcl.stats.from {
  width: 30%;
}

.Bid .wrapper .info .dcl.stats .ui.header {
  white-space: nowrap;
}

.Bid .wrapper .info .dcl.stats + .dcl.stats {
  margin-left: 48px;
}

.Bid .wrapper .info .dcl.stats .ui.header:not(.sub) {
  font-size: 17px;
  line-height: 26px;
}

.Bid .wrapper .info .dcl.stats .ui.header.sub {
  font-size: 13px;
  line-height: 18px;
}

.Bid .wrapper .info .dcl.stats .Profile {
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.Bid .wrapper .info .dcl.stats .Profile .name {
  color: var(--text);
}

.Bid .actions {
  flex: none;
  margin-left: 48px;
}

.Bid .actions .ui.button:not(.primary) {
  background-color: transparent;
  border: 2px solid var(--secondary-on-modal);
}

.Bid .actions .ui.button {
  width: 146px;
}

.Bid .actions .AcceptButton {
  display: inline-block;
  height: 48px;
}

.Bid .actions .ui.button + .ui.button,
.Bid .actions .AcceptButton + .ui.button {
  margin-left: 16px;
}

@media (max-width: 768px) {
  .Bid {
    height: auto;
  }

  .Bid .bid-row {
    flex-direction: column;
  }

  .Bid .image {
    width: 100%;
    height: auto;
  }

  .Bid .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .Bid .info {
    flex-direction: column;
    align-items: flex-start;
  }

  .Bid .wrapper .info .dcl.stats,
  .Bid .wrapper .info .dcl.stats.from {
    width: 100%;
  }

  .Bid .wrapper .info .dcl.stats + .dcl.stats {
    margin-left: 0px;
  }

  .Bid .actions {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100%;
  }

  .Bid .actions .ui.button {
    width: 100%;
  }

  .Bid .actions .ui.button + .ui.button,
  .Bid .actions .AcceptButton + .ui.button {
    margin-top: 12px;
    margin-left: 0px;
  }
}

.MyBidsPage .center {
  position: relative;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-text);
  margin-bottom: 20px;
}

.MyBidsPage .bids {
  margin-bottom: 48px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .MyBidsPage .Bid .image {
    display: none;
  }
  .Bid .wrapper .info .dcl.stats.from {
    width: 35%;
  }

  .MyBidsPage .Bid .actions {
    margin-left: 10px;
  }

  .MyBidsPage .Bid .actions .ui.button {
    width: 116px;
    min-width: 116px;
  }
}

.ui.cards > .ui.card.link,
.ui.card.link.LootboxCard {
  box-shadow: var(--shadow-2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
}

.LootboxCard .card-image-text.candy {
  align-items: flex-end;
}

.LootboxCard .product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #000000;
}

.LootboxCard .product-type-price-candy {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 0px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-family: var(--font-inter-bold);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
}

.LootboxCard .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.LootboxCard .product-info-number-card {
  margin: 0;
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #000000;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LootboxCard .product-description-container {
  height: 78px;
  min-height: 78px;
  justify-content: center;
}

.LootboxCard .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.LootboxCard .product-description-left-item {
  font-family: var(--font-inter-regular);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 150% */

  color: #000000;
  text-transform: uppercase;
  width: 100%;
}

.LootboxCard .product-description-right-nft {
  /* background-color: #6331f2; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex; */
}

.LootboxCard .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .LootboxCard .product-info-number-card {
    font-size: 13px;
  }

  .LootboxCard .product-type-price-candy {
    margin-top: -5px;
  }
}

.lootbox-container {
    width: 100%;
    height: 300px;
}
.LootboxDetails.grid-container {
  display: grid;
  grid-template-areas:
    'type item item'
    'type item item';
  grid-gap: 8px;
}

.LootboxDetails.grid-container.details-container {
  justify-content: flex-start;
}

.lootbox-detail-card .card-image .image-wrapper img {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%,-50%) scale(0.9);
          transform: translate(50%,-50%) scale(0.9);
}

.lootbox-detail-card .card-image-text.candy {
  align-items: flex-end;
}
.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

.lootbox-detail .block-container {
  /* margin-left: 40px; */
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .description-container {
    width: 100%;
  }
}

.show-text {
  font-size: 14px;
  color: #676370;
  font-weight: 400;
  font-family: var(--font-monospace);
}

.hidden-text {
  max-height: 185px;
  overflow: hidden;
  position: relative;
}

.hidden-text-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #232123 9%, rgba(35, 33, 35, 0) 97%);
}

.more-button {
  align-self: center;
  background-color: #676370;
  border-radius: 10px;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #232123;
  padding: 5px 22px;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.LootboxDetails.grid-container {
  display: flex;
  justify-content: flex-end;
}

.LootboxDetails.grid-container .lootbox-actions-container {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  grid-gap: 10px;
  gap: 10px
}

.LootboxDetails.grid-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.LootboxDetails.grid-container .lootbox-action-button.buy-action .ui.primary.button {
  background: linear-gradient(#d7b731, #f25f31);
  width: 40px;
  height: 50px;
  color: #000;
  text-transform: uppercase;
}

.LootboxDetails.grid-container .lootbox-action-button.bid-action .ui.primary.button {
  width: 40px;
  height: 50px;
}
.blueprint-container {
  background-image: url(/static/media/crafting-background.42670fde.svg);
  width: 600px;
  height: auto;
  padding: 15px 40px;
}

.blueprint-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 10px;
}

.blueprint-container .blueprint-item-container {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  align-items: center;
  border-top: 1px solid #444444;
}

.blueprint-container .blueprint-item-container .item-image {
  width: 109px;
  height: 105px;
}

.blueprint-item-description {
  margin-left: 35px;
}

.blueprint-item-description .blueprint-item-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.blueprint-item-description .blueprint-item-sub-title {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
}

.blueprint-item-description .blueprint-item-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.blueprint-container .action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.blueprint-container .progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background: #0f0f0f;
}

.blueprint-container .progress-container .token-text {
  font-family: PT Mono;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.blueprint-container .progress-container .progress-bar {
  margin-left: 10px;
}

.blueprint-container .craft-button.unclickable {
  position: relative;
  width: 72px;
  height: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 30px;
  background: linear-gradient(180deg, #979797 0.39%, #676370 100%) !important;
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  /* identical to box height */

  color: #000000;
}

.blueprint-container .craft-button.clickable {
  position: relative;
  width: 72px;
  height: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 30px;
  background: linear-gradient(180deg, #d7319e 0.39%, #6331f2 100%);
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  /* identical to box height */

  color: #ebebeb;
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }
  .blueprint-item-description {
    margin-left: 0px;
  }
  .blueprint-container .blueprint-item-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-items: center;
    border-top: 1px solid #444444;
  }
}

.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.lootbox-detail .lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}
.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .description-container {
    width: 100%;
  }
}

.show-text {
  font-size: 14px;
  color: #676370;
  font-weight: 400;
  font-family: var(--font-monospace);
}

.hidden-text {
  max-height: 160px;
  overflow: hidden;
  position: relative;
}

.hidden-text-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #232123 9%, rgba(35, 33, 35, 0) 97%);
}

.more-button {
  /* align-self: center; */
  font-family: var(--font-monospace);
  font-size: 15px;
  font-weight: 400;
  border: none;
  text-transform: capitalize;
  background-color: #000;
  color: #f231af;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.lootbox-container {
    width: 100%;
    height: 300px;
}
.CraftingModal {
  position: relative !important;
}

.CraftingModal .item-image.craft {
  background-image: url(/static/media/crafting-loading.48a41ec1.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 500px;
  border: 2px solid #d7319e;
  border-radius: 8px;
}

.CraftingModal .item-image.buy {
  background-image: url(/static/media/item-buy-loading.72fc67ca.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px;
  height: 500px;
  border: 2px solid #d7319e;
  border-radius: 8px;
}

.CraftingModal .item-image > img {
  width: 125px;
  object-fit: contain;
}

.ball {
  width: 125px;
  /* height: 100px; */
  /* border-radius: 50%; */
  /* background-color: #ff5722; */
  -webkit-animation: bounce 0.5s;
          animation: bounce 0.5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
          animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
}
/* Prefix Support */

ball {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
  }
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }
  .blueprint-item-description {
    margin-left: 0px;
  }
  .CraftingModal .item-image.craft {
    background-size: auto;
    width: 100%;
    height: 500px;
  }
  .CraftingModal .item-image.buy {
    background-size: auto;
    width: 100%;
    height: 500px;
  }
}

.kryptomon.probability {
  display: flex;
  /* width: 203px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kryptomon.probability .probability-title {
  max-width: 100px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  text-align: center;
  line-height: 1.2rem;
}

.kryptomon.probability .circle1 {
  width: 120px;
  height: 120px;
  background-color: #282828;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}

.kryptomon.probability .circle2 {
  width: 75px;
  height: 75px;
  background-color: #363636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kryptomon.svg {
  width: 38px;
  height: 35px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.probability {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .kryptomon.probability {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.kryptomon.choose-pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.kryptomon.choose-pair .card-area {
  display: flex;
  justify-content: center;
  width: 100%;
}

.kryptomon.choose-pair .card-area .empty-card-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 375px;
  width: 100%;
}

.kryptomon.choose-pair .card-area .empty-card-box .card-content {
  width: 200px;
}

.kryptomon.choose-pair .card-area .empty-card-box .card-title {
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  text-align: center;
  margin-bottom: 44px;
}

.kryptomon.choose-pair .card-area .remove-button {
  margin-top: 18px;
}

.kryptomon.choose-pair .card-area .remove-button .kicon {
  font-size: 12px;
  color: #676370;
  letter-spacing: 0;
}

.kryptomon.choose-pair .card-area .vertical-line {
  border-left: 1px solid rgba(151, 151, 151, 0.21);
}

.kryptomon.choose-pair .compare-button {
  background: linear-gradient(#D7B731, #F25F31) !important;
  color: var(--background) !important;
  margin: 15px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.choose-pair .card-area {
    display: block;
  }

  .kryptomon.choose-pair .card-area .vertical-line {
    border-top: 1px solid rgba(151, 151, 151, 0.21);
    border-left: 0px;
    margin-top: 44px;
    padding-top: 44px;
  }
}

@media (max-width: 768px) {
  .kryptomon.choose-pair .card-area {
    display: block;
  }

  .kryptomon.choose-pair .card-area .vertical-line {
    border-top: 1px solid rgba(151, 151, 151, 0.21);
    border-left: 0px;
    margin-top: 44px;
    padding-top: 44px;
  }
}
.kryptomon.breeding-modal .ui.grid>.row {
  padding: 0 !important;
}

.kryptomon.breeding-modal .kryptomon-box.left {
  display: flex;
  padding-right: 82px;
}

.kryptomon.breeding-modal .kryptomon-box.left > div {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.kryptomon.breeding-modal .kryptomon-box.left .NFTImage {
  width: 150px;
}

.kryptomon.breeding-modal .kryptomon-box.left .detail {
  flex-direction: column;
}

.kryptomon.breeding-modal .kryptomon-box.right {
  display: flex;
  padding-left: 82px;
}

.kryptomon.breeding-modal .kryptomon-box.right > div {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .kryptomon.breeding-modal .ui.grid>.row {
    flex-direction: column;
  }

  .ui.modal > .content {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ui.modal > .content {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
.kryptomon.breeding-modal-nft-detail {
  display: flex;
  align-items: center;
  padding-right: 82px;
}

.kryptomon.breeding-modal-nft-detail.right {
  flex-direction: row-reverse;
  padding-right: 0px;
  padding-left: 82px;
}

.kryptomon.breeding-modal-nft-detail > div {
  display: flex;
  width: 50%;
}

.kryptomon.breeding-modal-nft-detail .image {
  display: flex;
  justify-content: flex-end;
}

.kryptomon.breeding-modal-nft-detail.right .image {
  display: flex;
  justify-content: start;
}

.kryptomon.breeding-modal-nft-detail .image .NFTImage {
  width: 150px;
}

.kryptomon.breeding-modal-nft-detail .detail {
  flex-direction: column;
  color: #E7E3D7;
  letter-spacing: 0;
}

.kryptomon.breeding-modal-nft-detail .detail .detail-title {
  display: flex;
  font-size: 17.17px;
  align-items: center;
  justify-content: flex-end;
}

.kryptomon.breeding-modal-nft-detail.right .detail .detail-title {
  flex-direction: row-reverse;
  justify-content: start;
}

.kryptomon.breeding-modal-nft-detail .detail .detail-title .product-type-icon {
  margin-left: 15px;
}

.kryptomon.breeding-modal-nft-detail.right .detail .detail-title .product-type-icon {
  margin-left: 0px;
  margin-right: 15px;
  align-self: center;
}

.kryptomon.breeding-modal-nft-detail .detail .detail-content {
  font-size: 10px;
  text-align: right;
  margin-top: 18px;
}

.kryptomon.breeding-modal-nft-detail.right .detail .detail-content {
  font-size: 10px;
  text-align: left;
  margin-top: 18px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.breeding-modal-nft-detail .detail .detail-content {
    font-size: 15px;
  }

  .kryptomon.breeding-modal-nft-detail.right .detail .detail-content {
    font-size: 15px;
  }

  .kryptomon.breeding-modal-nft-detail .detail .detail-title {
    font-size: 22.17px;
  }
}

@media (max-width: 768px) {
  .kryptomon.breeding-modal-nft-detail {
    padding-right: 10px;
  }

  .kryptomon.breeding-modal-nft-detail.right {
    padding-left: 10px;
  }

  .kryptomon.breeding-modal-nft-detail .detail .detail-content {
    font-size: 15px;
  }

  .kryptomon.breeding-modal-nft-detail.right .detail .detail-content {
    font-size: 15px;
  }

  .kryptomon.breeding-modal-nft-detail .detail .detail-title {
    font-size: 22.17px;
  }
}
.kryptomon.breeding-modal-fee {
  display: flex;
  flex: 1 1;
}

.kryptomon.breeding-modal-fee > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  align-self: flex-end;
  width: 40%;
}
.dna-chart-mynft {
  width: 300px;
}

.dna-chart-selectednft {
  width: 300px;
}

.kryptomon.breeding-modal-fee .my-kryptomon {
}

.kryptomon.breeding-modal-fee .fee-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 31px;
}

.kryptomon.breeding-modal-fee .fee-detail .fee-detail-info {
  text-align: center;
  font-size: 12px;
  color: #E7E3D7;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
}

.kryptomon.breeding-modal-fee .fee-detail .breed-button {
  margin: 20px 0 10px 0;
  background: linear-gradient(#D7B731, #F25F31) !important;
  color: var(--background) !important;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.breeding-modal-fee {
    display: block;
    justify-content: center;
    width: 100%;
  }

  .kryptomon.breeding-modal-fee .my-kryptomon {
    width: 100%;
  }

  .kryptomon.breeding-modal-fee > div {
    width: 100%;
  }

  .kryptomon.breeding-modal-fee .fee-detail .fee-detail-info {
    font-size: 17px;
    line-height: 25px;
  }
}

@media (max-width: 768px) {
  .kryptomon.breeding-modal-fee {
    display: block;
    justify-content: center;
    width: 100%;
  }

  .kryptomon.breeding-modal-fee .my-kryptomon {
    width: 100%;
  }

  .kryptomon.breeding-modal-fee > div {
    width: 100%;
  }

  .kryptomon.breeding-modal-fee .fee-detail .fee-detail-info {
    font-size: 17px;
    line-height: 25px;
  }
}
.kryptomon.breeding-modal-probability {
  margin: 58px 0;
}

.kryptomon.breeding-modal-probability .value {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  text-align: center;
}

.kryptomon.breeding-modal-probability .probability-chart-box {
  display: flex;
  justify-content: center;
}

.kryptomon.breeding-modal-probability .probability-chart-box .probability-chart {
  margin-top: 20px;
  width: 60%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.breeding-modal-probability .probability-chart-box .probability-chart {
    margin-top: 20px;
    width: 100%;
  }

  .kryptomon.breeding-modal-probability .value {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .kryptomon.breeding-modal-probability .probability-chart-box .probability-chart {
    margin-top: 20px;
    width: 100%;
  }

  .kryptomon.breeding-modal-probability .value {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .sidebar .NFTSidebar {
    display: none;
  }
}

.BNFTCard .product-description {
  align-items: center;
}

.BNFTCard .card-image-container {
  height: 365px;
}

.bnft-detail .Row .Column {
  width: calc(100% - 272px);
}

.bnft-detail .bnft-container {
  flex-direction: row;
}

.bnft-detail .bnft-slider {
  max-width: 70%;
}
.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}
.each-slide-effect > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 750px;
  /* width: 50%; */
}

.bnft-detail .Row .Column {
  width: calc(100% - 272px);
}

.bnft-detail .bnft-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.bnft-detail .bnft-description-container {
  padding-left: 42px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.bnft-detail .ui.card.link.BNFTCard {
  height: 100%;
  align-self: flex-start;
}

.bnft-detail .ui.card.link.BNFTDetailsCard {
  height: 100%;
  align-self: flex-start;
}

.bnft-detail .bnft-description-content > h3 > strong {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.bnft-detail .bnft-description-content > p > span {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.LootboxDetails.grid-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }
  .bnft-detail .Row .Column {
    width: 100%;
  }
  .bnft-detail .bnft-slider {
    max-width: 100%;
  }
  .bnft-detail .bnft-description-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* INITIALIZERS + DEFAULTS
 * ============================== */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-size: 2rem;
}

/* BASE APP
  * ============================== */
.App__header {
  background-color: #222;
  background-image: url('https://unsplash.it/1000/300?image=823');
  background-size: cover;
  color: white;
  padding: 10px 10px;
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

.Product-wrapper {
  max-width: 900px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.button {
  background-color: #2752ff;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  cursor: default;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
  * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

.Cart--open {
  z-index: 1000;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
  cursor: pointer;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
}

.Cart__checkout .ui.primary.button {
  width: 100%;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
}

.Cart-info__small {
  font-family: 'Poppins';
  padding-left: 15px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.88);
}

.Cart-info__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
  * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: black;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
  * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

/* CUSTOMER AUTH
  * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

.BNFTDetailsCard .product-description {
  align-items: center;
}

.BNFTDetailsCard .card-image-container {
  height: 365px;
}

.BNFTDetailsCard .card-image-container .card-image {
  background-color: #000;
}

.ui.card.BNFTDetailsCard {
  height: 100%;
  align-self: flex-start;
}

.BNFTDetailsCard
  .card-image-container
  .card-image
  .product-images-slider-thumbs
  .swiper-button-next:after,
.swiper-button-prev:after {
  font-family: 'swiper-icons';
  font-size: 16px;
  text-transform: none !important;
  letter-spacing: 0;
  /* // font-feature-settings: ; */
  -webkit-font-feature-settings: normal, ;
          font-feature-settings: normal, ;
  font-variant: normal;
  font-variant: initial;
  line-height: 1;
  color: #ffffff;
  font-weight: 800;
  /* background-image: url(https://cdn.shopify.com/s/files/1/0573/9891/9191/products/BOX_3_GEN_7_Silver_N.png?v=1664280360); */
  background: #7a7a7a;
  padding: 6px;
  border-radius: 14px;
  width: 27px;
  height: 27px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* right: -30px; */
}

@media (max-width: 768px) {
  .ui.card.BNFTDetailsCard {
    align-self: center;
  }
}

.product-images-slider .swiper-slide{padding-top:100%;background-color:#2a2a2a;overflow:hidden;position:relative}.product-images-slider .swiper-slide img{position:absolute;top:0;left:0;width:100%}.product-images-slider .swiper-button-prev{left:0;color:#000}.product-images-slider .swiper-button-next{right:0;color:#000}.product-images-slider-thumbs .swiper-slide{cursor:pointer;background-color:#2a2a2a;border:2px solid #2a2a2a;border-radius:5px;margin-top:5px}.product-images-slider-thumbs .swiper-slide-thumb-active{border-color:#f231af}.product-images-slider-thumbs .swiper-button-prev{left:0;color:#000}.product-images-slider-thumbs .swiper-button-next{right:0;color:#000}.product-images-slider-thumbs-wrapper{width:100%;padding-top:100%;overflow:hidden;position:relative}.product-images-slider-thumbs-wrapper img{position:absolute;top:0;left:0;width:100%}
.BNFTDetails.grid-container {
  display: flex;
  justify-content: space-between;
}
.BNFTDetails.grid-container .lootbox-actions-container {
  flex-direction: row;
  display: flex;
}

.BNFTDetails.grid-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
  text-decoration: line-through;
}

.BNFTDetails.grid-container
  .lootbox-action-button.buy-action
  .ui.primary.button {
  background: linear-gradient(#d7b731, #f25f31);
  width: 40px;
  height: 50px;
  color: #000;
  text-transform: uppercase;
}

.BNFTDetails.grid-container
  .lootbox-action-button.bid-action
  .ui.primary.button {
  width: 40px;
  height: 50px;
}

.bnft-description-container .detail-big-text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  color: #e7e3d7;
}

.SettingsPage .left-column {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.SettingsPage .row {
  margin-bottom: 70px;
}

.SettingsPage .dcl.blockie {
  margin-right: 24px;
  float: left;
}

.SettingsPage .address-container {
  margin-top: 7px;
  float: left;
}

.SettingsPage .address {
  margin-bottom: 8px;
  font-size: 21px;
}

.SettingsPage .copy-text,
.SettingsPage .link {
  color: var(--primary);
}

.SettingsPage .link {
  cursor: pointer;
}

.SettingsPage .balance {
  display: flex;
  align-items: center;
}

.SettingsPage .dcl.mana {
  font-size: 21px;
  margin-bottom: 0;
}

.SettingsPage .buy-more {
  margin-left: 24px;
}

.SettingsPage .authorization-checks > label {
  display: block;
  font-size: 17px;
  margin-bottom: 17px;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .SettingsPage .ui.grid > .row {
    flex-direction: column;
    margin-bottom: 48px;
  }

  .SettingsPage .ui.grid > .row > .column:first-child {
    margin-bottom: 16px;
  }

  .SettingsPage .blockie-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .SettingsPage .address-container {
    margin-top: 24px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .SettingsPage .address-container .address {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.BuyPage .ui.header {
    margin-bottom: 8px;
  }
  
  .BuyPage .buttons {
    margin-top: 32px;
  }
  
  .BuyPage .error {
    color: var(--danger);
  }
  
.LootboxCard .product-type-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  grid-gap: 10px;
  gap: 10px;
  background: #6331f2;
  border-radius: 50px;
}

.lootbox-detail-card .card-image .image-wrapper img {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%) scale(0.9);
          transform: translate(50%, -50%) scale(0.9);
}

.lootbox-detail-card .card-image-text.candy {
  align-items: flex-end;
}

.ui.cards > .ui.card.link,
.ui.card.link.lootbox-detail-card {
  box-shadow: var(--shadow-2);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
}

.lootbox-detail .ui.card.link.lootbox-detail-card {
  height: 100%;
}

.lootbox-detail-card .card-image-text.candy {
  align-items: flex-end;
}

.lootbox-detail-card .product-type-price {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.lootbox-detail-card .product-type-price-candy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  font-weight: 700;
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 20px;
}

.lootbox-detail-card .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.lootbox-detail-card .product-info-number-card {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #000000;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lootbox-detail-card .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.lootbox-detail-card .product-description-left-item {
  color: #000000;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}

.lootbox-detail-card .product-description-right-nft {
  background-color: #6331f2;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex;
}

.lootbox-detail-card .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

.blueprint_container {
  width: 100%;
  padding: 26px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.blueprint_container .blueprint-buy-button {
  padding: 10px 32px;
  border-radius: 32px;
  background: linear-gradient(90deg, #F231AF 0%, #6331F2 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.blueprint_container .blueprint-buy-button .blueprint-buy-button-text {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.blueprint-seller-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 3px;
  gap: 3px;
}

.blueprint-seller-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.blueprint-seller-address {
  width: 440px;
  background-color: #101011;
  border-radius: 8px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #777777;
  padding: 5px 15px;
}

.blueprint-seller-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 70px;
  grid-gap: 8px;
  gap: 8px;
}

.blueprint-seller-title {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #EBEBEB;
}

.blueprint-price {
  flex: 1 1;
  background-color: #101011;
  border-radius: 8px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #EBEBEB;
  padding: 5px 15px;
}

.blueprint-border-bottom {
  border-bottom: 1px solid #444444;
}

.blueprint_container button.ui.button {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }

  .blueprint-seller-address {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.orderitemdetail-seller-container {
  background-image: url(/static/media/crafting-background.42670fde.svg);
  background-size: cover;
  height: auto;
  padding: 0px 25px;
  border-radius: 10px;
  border: 1px solid #29292A;
  max-height: 500px;
  overflow: auto;
}

.lootbox-detail .lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-left: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

.connect-center {
  text-align: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}
.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .description-container {
    width: 100%;
  }
}

.show-text {
  font-size: 14px;
  color: #676370;
  font-weight: 400;
  font-family: var(--font-monospace);
}

.hidden-text {
  max-height: 160px;
  overflow: hidden;
  position: relative;
}

.hidden-text-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #232123 9%, rgba(35, 33, 35, 0) 97%);
}

.more-button {
  /* align-self: center; */
  font-family: var(--font-monospace);
  font-size: 15px;
  font-weight: 400;
  border: none;
  text-transform: capitalize;
  background-color: #000;
  color: #f231af;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.confirm-modal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-gap: 46px;
  gap: 46px;
}

.confirm-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-gap: 10px;
  gap: 10px;
}

.confirm-modal-btn{
  cursor: pointer;
}

.confirm-modal-buy {
  padding: 15px 54px;
  border-radius: 30px;
  background: linear-gradient(180deg, #D7B731 0%, #F25F31 100%);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: black;
  text-transform: uppercase;
}

.confirm-modal-right-container {
  width: 413px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #29292A;
}

.confirm-modal-cancel {
  padding: 15px 54px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
}

.confirm-modal-info-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: white;
  text-transform: uppercase;
}

.confirm-modal-info-desc {}

.confirm-modal-image {
  width: 300px;
}
.ItemAction .back {
  background-image: url(/static/media/back.7cd73216.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.ItemAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.ItemAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 80px;
}

.ItemAction .Row .right {
  flex: 1.25 1 auto;
}

.ItemAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.ItemAction .Row .payment #simplex-form #checkout-element > iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.ItemAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.ItemAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.ItemAction .Row .payment > div > iframe {
  pointer-events: all;
}

.ItemAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.ItemAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

.ItemAction .item-image-wrapper img {
  width: 150px;
  border-radius: 20px;
  float: right;
}

@media (max-width: 768px) {
  .ItemAction > .Row {
    flex-direction: column;
  }

  .ItemAction > .Row .Column.left,
  .ItemAction > .Row .nft-image-wrapper,
  .ItemAction > .Row .ui.button {
    width: 100%;
  }

  .ItemAction > .Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .ItemAction > .Row .nft-image-wrapper,
  .ItemAction > .Row .ui.button + .ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .ItemAction .Row .payment #simplex-form #checkout-element > iframe {
    width: auto;
    padding: 25px 10px;
  }
}

.CancelItemPage .ui.header {
  margin-bottom: 8px;
}

.CancelItemPage .subtitle {
  margin-bottom: 32px;
}

.ui.inverted.primary.button:focus {
  color: var(--primary);
  background-color: transparent;
}

.ui.loading.button:after {
  border-color: var(--primary) transparent transparent;
}

.ui.button + .ui.button {
  margin-left: 0px;
}

.ui.primary.button {
  background: linear-gradient(#d7319e, #6331f2);
  border-radius: var(--button-border-radius);
  font-family: var(--font-poppins);
  text-transform: none;
  text-transform: initial;
  font-size: 21px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 250px;
}

.ui.cards a.card:hover,
.ui.link.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover {
  background-color: var(--card);
}

.dcl.tabs a {
  color: var(--text);
}

.dcl.tabs .shop {
  color: #f3ba2f;
}

.dcl.tabs a img {
  display: inherit;
  margin-right: 5px;
}

.dcl.logo {
  background-image: url(/static/media/kryptomon-logo.db08af7b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0px;
  width: 111px;
  height: 48px;
  margin-top: -10px;
}

.dcl.tabs {
  font-family: var(--font-poppins);
  font-weight: 500;
}

.dcl.tab.active {
  font-family: var(--font-poppins) !important;
  font-weight: 500;
}

@media (max-width: 768px) {
  .dcl.tabs a img {
    display: none;
  }
}

.ui.form .dcl.field .ui.input input {
  color: var(--text);
  background: transparent;
}

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  width: 26px;
  height: 26px;
  opacity: 1;
}

.ui.radio.checkbox.checked input.hidden + label:after {
  top: 0px;
  left: -1px;
  width: 29px;
  height: 32px;
}

.ui.popup,
.ui.inverted.popup {
  height: auto;
}

@font-face {
  font-family: 'Mister-London-Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Mister-London-Sans'),
    url(/static/media/Mister-London-Sans.bdbcd42f.otf) format('opentype');
}

@font-face {
  font-family: 'Nikkyou Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Nikkyou Sans'),
    url(/static/media/NikkyouSans-mLKax.4ef8929f.ttf) format('opentype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  src: local('Space Grotesk'),
    url(/static/media/SpaceGrotesk-VariableFont_wght.1036357b.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'),
    url(/static/media/Inter-VariableFont_slnt,wght.aadb65ac.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Inter-Bold'),
    url(/static/media/Inter-VariableFont_slnt,wght.aadb65ac.ttf) format('opentype');
}

:root {
  --background: #000;

  --button-border-radius: 30px;
  --button-height: 48px;
  --button-width: 150px;

  --font-monospace: 'PT Mono';
  --font-family: 'Mister-London-Sans';
  --font-poppins: 'Poppins';
  --font-nikkyou: 'Nikkyou Sans';
  --font-space: 'Space Grotesk';
  --font-inter-regular: 'Inter-Regular';
  --font-inter-bold: 'Inter-Bold';
  --font-size-medium: 18px;
  --font-size-small: 14px;

  --primary: #f231af;
  --page-header-height: 490px;
}

body {
  margin: 0;
  font-family: 'PT Mono', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: var(--font-monospace), -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.danger-text {
  color: var(--danger);
}

@media (max-width: 768px) {
  .dcl.navbar.overlay .ui.container {
    width: calc(100% - 32px) !important;
  }
  .ui.modal .actions {
    flex-direction: column-reverse;
    align-items: center;
  }

  .ui.modal .actions .ui.button + .ui.button {
    margin-left: 0px;
  }

  .ui.small.modal > .header:not(.ui) {
    text-align: center;
  }
  .ui.small.modal > .actions > .ui.button {
    min-width: 60%;
  }
  .ui.modal > .ui.form > .actions {
    align-items: center;
  }
  .dcl.page.fullscreen.NFTPage {
    overflow-x: hidden;
  }
  .BreedPriceModal > .actions > .ui.button {
    min-width: 250px;
  }
}

.Banner {
  background: #6331f2;
}
.Banner .ui.container {
  padding: 6px 0 5px;
}
.Banner .indexing-delay {
  font-weight: 600;
  font-family: var(--font-family);
}
