.Slideshow {
  margin-bottom: 70px;
  position: relative;
  width: 99%;
}

/* shadow */
.Slideshow:after {
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 30%,
    rgba(15, 15, 15, 0) 80%,
    rgba(15, 15, 15, 0)
  );
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: -30px;
  width: 220px;
}

@media (max-width: 768px) {
  .Slideshow:after {
    width: auto;
  }

  .Slideshow .dcl.header-menu {
    padding-left: 20px;
  }

  .Slideshow .nfts {
    padding-left: 20px;
  }
}

.Slideshow .nfts {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  position: relative;
  min-height: 336px;
}

.Slideshow .nfts .NFTCard,
.Slideshow .nfts .ui.card.NFTCard:first-child {
  flex: 0 0 auto;
  width: 220px;
  margin-right: 20px;
  height: 280px;
  margin-top: 12px;
}

.Slideshow .dcl.header-menu {
  margin-bottom: -23px;
}

.Slideshow i.caret {
  background-image: url(../../../images/forward.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 13px;
  margin-left: 4px;
}

.Slideshow .ui.button.basic {
  display: flex;
  align-items: center;
}

.Slideshow .ui.button.basic,
.Slideshow .ui.button.basic:hover {
  color: var(--text) !important;
  font-family: var(--font-poppins);
}

.Slideshow .header-menu-right {
  z-index: 1;
}
