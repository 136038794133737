.ItemAction .back {
  background-image: url(../../images/back.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.ItemAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.ItemAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 80px;
}

.ItemAction .Row .right {
  flex: 1.25 1 auto;
}

.ItemAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.ItemAction .Row .payment #simplex-form #checkout-element > iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.ItemAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.ItemAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.ItemAction .Row .payment > div > iframe {
  pointer-events: all;
}

.ItemAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.ItemAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

.ItemAction .item-image-wrapper img {
  width: 150px;
  border-radius: 20px;
  float: right;
}

@media (max-width: 768px) {
  .ItemAction > .Row {
    flex-direction: column;
  }

  .ItemAction > .Row .Column.left,
  .ItemAction > .Row .nft-image-wrapper,
  .ItemAction > .Row .ui.button {
    width: 100%;
  }

  .ItemAction > .Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .ItemAction > .Row .nft-image-wrapper,
  .ItemAction > .Row .ui.button + .ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .ItemAction .Row .payment #simplex-form #checkout-element > iframe {
    width: auto;
    padding: 25px 10px;
  }
}
