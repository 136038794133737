.confirm-modal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 46px;
}

.confirm-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.confirm-modal-btn{
  cursor: pointer;
}

.confirm-modal-buy {
  padding: 15px 54px;
  border-radius: 30px;
  background: linear-gradient(180deg, #D7B731 0%, #F25F31 100%);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: black;
  text-transform: uppercase;
}

.confirm-modal-right-container {
  width: 413px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #29292A;
}

.confirm-modal-cancel {
  padding: 15px 54px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
}

.confirm-modal-info-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: white;
  text-transform: uppercase;
}

.confirm-modal-info-desc {}

.confirm-modal-image {
  width: 300px;
}