.blueprint-container {
  background-image: url(../../../../images/items/crafting-background.svg);
  width: 600px;
  height: auto;
  padding: 15px 40px;
}

.blueprint-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 10px;
}

.blueprint-container .blueprint-item-container {
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  align-items: center;
  border-top: 1px solid #444444;
}

.blueprint-container .blueprint-item-container .item-image {
  width: 109px;
  height: 105px;
}

.blueprint-item-description {
  margin-left: 35px;
}

.blueprint-item-description .blueprint-item-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.blueprint-item-description .blueprint-item-sub-title {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
}

.blueprint-item-description .blueprint-item-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.blueprint-container .action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.blueprint-container .progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background: #0f0f0f;
}

.blueprint-container .progress-container .token-text {
  font-family: PT Mono;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.blueprint-container .progress-container .progress-bar {
  margin-left: 10px;
}

.blueprint-container .craft-button.unclickable {
  position: relative;
  width: 72px;
  height: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  border-radius: 30px;
  background: linear-gradient(180deg, #979797 0.39%, #676370 100%) !important;
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  /* identical to box height */

  color: #000000;
}

.blueprint-container .craft-button.clickable {
  position: relative;
  width: 72px;
  height: 27px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  border-radius: 30px;
  background: linear-gradient(180deg, #d7319e 0.39%, #6331f2 100%);
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
  /* identical to box height */

  color: #ebebeb;
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }
  .blueprint-item-description {
    margin-left: 0px;
  }
  .blueprint-container .blueprint-item-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    align-items: center;
    border-top: 1px solid #444444;
  }
}
