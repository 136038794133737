.SellPage .ui.header {
  margin-bottom: 8px;
}

.SellPage .subtitle {
  margin-bottom: 32px;
}

.SellPage .subtitle.error {
  color: var(--danger);
}

.SellPage .warning {
  color: var(--danger);
  margin-bottom: 24px;
}

.SellPage .message {
  font-family: var(--font-family);
  font-size: 12px;
  margin-bottom: 10px;
}

.SellPage .form-fields {
  width: 420px;
}

.SellPage .buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}


@media only screen and (max-width: 767px) {
  .SellPage .form-fields {
    width: 100%;
  }

  .SellPage .form-fields .dcl.field {
    min-width: auto;
    margin-top: 20px;
  }
}

.NFTAction .back {
  background-image: url(../../../images/back.svg);
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: 9px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px rgba(115, 110, 125, 0.4);
  background-size: 7px;
  margin-top: 5vh;
  margin-bottom: 10vh;
  cursor: pointer;
  margin-left: 2px;
}

.NFTAction .back:hover {
  border: solid 1px rgba(255, 255, 255, 0.5);
}

.NFTAction .Row .left {
  flex: 0.75 1 auto;
  text-align: right;
  margin-right: 40px;
}

.NFTAction .Row .right {
  flex: 1.25 1 auto;
}

.NFTAction .Row .payment .header {
  font-family: 'Mister-London-Sans';
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 28px;
  letter-spacing: 0.4px;
  color: darkgrey;
}

.NFTAction .Row .payment #simplex-form #checkout-element>iframe {
  width: 400px;
  height: 500px;
  padding: 50px 30px;
  border-radius: 20px;
  background: #24213a;
  /* background-color: #242129; */
  pointer-events: all;
}

.NFTAction .Row .payment #simplex-form {
  margin: 30px 0px;
}

.NFTAction .Row .payment #simplex-iframe-form {
  overflow-y: hidden;
  overflow-x: hidden;
}

.simplex-continue-button {
  background-color: red;
}

.NFTAction .Row .payment>div>iframe {
  pointer-events: all;
}

.NFTAction .Row .buy-kmon {
  width: 200px !important;
  margin-top: 10px !important;
}

.NFTAction .nft-image-wrapper {
  width: 240px;
  border-radius: 16px;
  overflow: hidden;
  display: inline-block;
}

@media (max-width: 768px) {
  .NFTAction>.Row {
    flex-direction: column;
  }

  .NFTAction>.Row .Column.left,
  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button {
    width: 100%;
  }

  .NFTAction>.Row .buttons {
    display: flex;
    flex-direction: column-reverse;
  }

  .NFTAction>.Row .nft-image-wrapper,
  .NFTAction>.Row .ui.button+.ui.button {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  .NFTAction .Row .payment #simplex-form #checkout-element>iframe {
    width: auto;
    padding: 25px 10px;
  }
}

.lootbox-detail .Row .Column {
  width: calc(100% - 272px);
}

.lootbox-separator {
  display: flex;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 32px;
  margin-bottom: 42px;
}

.lootbox-detail .lootbox-description-container {
  padding-left: 42px;
}

.lootbox-detail .lootbox-craft-container {
  padding: 42px;
}

.lootbox-detail .lootbox-craft-container .craft-static {
  margin-bottom: 40px;
}

.lootbox-detai .lootbox-description-name {
  font-size: 16px;
  font-family: var(--font-poppins);
  color: #ffff;
  font-weight: 700;
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.ConfirmNumberOfItemsModal .dcl.field .ui.sub.header {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .ConfirmNumberOfItemsModal .dcl.field {
    width: 100%;
    min-width: auto;
  }

  .lootbox-detail .Row .Column {
    width: calc(100%);
  }

  .lootbox-detail .lootbox-description-container {
    padding-left: 0px;
  }

  .lootbox-detail .lootbox-separator {
    margin-left: 0px;
  }

  .lootbox-detail .lootbox-craft-container {
    padding: 20px 0px;
  }
}