.block-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-family: var(--font-poppins);
  font-weight: 700;
  font-size: 20px;
  margin: 20px 0;
}

.title-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
}

.title-icon {
  width: 35px;
  height: 35px;
  margin-right: 1em;
}
