.BNFTDetailsCard .product-description {
  align-items: center;
}

.BNFTDetailsCard .card-image-container {
  height: 365px;
}

.BNFTDetailsCard .card-image-container .card-image {
  background-color: #000;
}

.ui.card.BNFTDetailsCard {
  height: 100%;
  align-self: flex-start;
}

.BNFTDetailsCard
  .card-image-container
  .card-image
  .product-images-slider-thumbs
  .swiper-button-next:after,
.swiper-button-prev:after {
  font-family: 'swiper-icons';
  font-size: 16px;
  text-transform: none !important;
  letter-spacing: 0;
  /* // font-feature-settings: ; */
  font-variant: initial;
  line-height: 1;
  color: #ffffff;
  font-weight: 800;
  /* background-image: url(https://cdn.shopify.com/s/files/1/0573/9891/9191/products/BOX_3_GEN_7_Silver_N.png?v=1664280360); */
  background: #7a7a7a;
  padding: 6px;
  border-radius: 14px;
  width: 27px;
  height: 27px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* right: -30px; */
}

@media (max-width: 768px) {
  .ui.card.BNFTDetailsCard {
    align-self: center;
  }
}
