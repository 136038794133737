@font-face {
  font-family: 'Mister-London-Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Mister-London-Sans'),
    url('./fonts/Mister-London-Sans.otf') format('opentype');
}

@font-face {
  font-family: 'Nikkyou Sans';
  font-style: normal;
  font-weight: normal;
  src: local('Nikkyou Sans'),
    url('./fonts/NikkyouSans-mLKax.ttf') format('opentype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  src: local('Space Grotesk'),
    url('./fonts/SpaceGrotesk-VariableFont_wght.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Inter-Regular'),
    url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('opentype');
}

@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Inter-Bold'),
    url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('opentype');
}

:root {
  --background: #000;

  --button-border-radius: 30px;
  --button-height: 48px;
  --button-width: 150px;

  --font-monospace: 'PT Mono';
  --font-family: 'Mister-London-Sans';
  --font-poppins: 'Poppins';
  --font-nikkyou: 'Nikkyou Sans';
  --font-space: 'Space Grotesk';
  --font-inter-regular: 'Inter-Regular';
  --font-inter-bold: 'Inter-Bold';
  --font-size-medium: 18px;
  --font-size-small: 14px;

  --primary: #f231af;
  --page-header-height: 490px;
}

body {
  margin: 0;
  font-family: var(--font-monospace), -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.danger-text {
  color: var(--danger);
}

@media (max-width: 768px) {
  .dcl.navbar.overlay .ui.container {
    width: calc(100% - 32px) !important;
  }
  .ui.modal .actions {
    flex-direction: column-reverse;
    align-items: center;
  }

  .ui.modal .actions .ui.button + .ui.button {
    margin-left: 0px;
  }

  .ui.small.modal > .header:not(.ui) {
    text-align: center;
  }
  .ui.small.modal > .actions > .ui.button {
    min-width: 60%;
  }
  .ui.modal > .ui.form > .actions {
    align-items: center;
  }
  .dcl.page.fullscreen.NFTPage {
    overflow-x: hidden;
  }
  .BreedPriceModal > .actions > .ui.button {
    min-width: 250px;
  }
}
