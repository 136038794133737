.kryptomon.probability {
  display: flex;
  /* width: 203px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.kryptomon.probability .probability-title {
  max-width: 100px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.86px;
  text-align: center;
  line-height: 1.2rem;
}

.kryptomon.probability .circle1 {
  width: 120px;
  height: 120px;
  background-color: #282828;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}

.kryptomon.probability .circle2 {
  width: 75px;
  height: 75px;
  background-color: #363636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kryptomon.svg {
  width: 38px;
  height: 35px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .kryptomon.probability {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .kryptomon.probability {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}