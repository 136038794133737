.WearableDetail .PageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.WearableDetail .Highlight .left > div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
}

.horizontal-nft-scroll {
  display: flex;
  flex-direction: row;
  /* max-width: 50%; */
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.horizontal-nft-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.horizontal-nft-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.horizontal-nft-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #101010;
}
.horizontal-nft-scroll::-webkit-scrollbar-track {
  background-color: #313031;
  border-radius: 8px;
}

.related-title-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.related-title-area h6:nth-child(2) {
  cursor: pointer !important;
}

.WearableDetail .rarity {
  font-size: 13px;
  line-height: 18px;
  border-radius: 5px;
  padding: 7px 12px;
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: default;
  margin-left: 16px;
  margin-top: 6px;
  cursor: pointer;
}

.WearableDetail .Highlight .body_shape {
  background-image: url(../../../images//wearables/BodyShapeIcon.svg);
}

.WearableDetail .Highlight .earring {
  background-image: url(../../../images//wearables/EarringsIcon.svg);
}

.WearableDetail .Highlight .eyebrows {
  background-image: url(../../../images//wearables/EyebrowIcon.svg);
}

.WearableDetail .Highlight .eyes {
  background-image: url(../../../images//wearables/EyesIcon.svg);
}

.WearableDetail .Highlight .eyewear {
  background-image: url(../../../images//wearables/EyewearIcon.svg);
}

.WearableDetail .Highlight .facial_hair {
  background-image: url(../../../images//wearables/FacilHairIcon.svg);
}

.WearableDetail .Highlight .feet {
  background-image: url(../../../images//wearables/FeetIcon.svg);
}

.WearableDetail .Highlight .hair {
  background-image: url(../../../images//wearables/HairIcon.svg);
}

.WearableDetail .Highlight .hat {
  background-image: url(../../../images//wearables/HatIcon.svg);
}

.WearableDetail .Highlight .helmet {
  background-image: url(../../../images//wearables/HelmetIcon.svg);
}

.WearableDetail .Highlight .lower_body {
  background-image: url(../../../images//wearables/LowerBodyIcon.svg);
}

.WearableDetail .Highlight .mask {
  background-image: url(../../../images//wearables/MaskIcon.svg);
}

.WearableDetail .Highlight .mouth {
  background-image: url(../../../images//wearables/MouthIcon.svg);
}

.WearableDetail .Highlight .tiara {
  background-image: url(../../../images//wearables/TiaraIcon.svg);
}

.WearableDetail .Highlight .top_head {
  background-image: url(../../../images//wearables/TopHeadIcon.svg);
}

.WearableDetail .Highlight .upper_body {
  background-image: url(../../../images//wearables/UpperBodyIcon.svg);
}

.WearableDetail .Highlight .left > .BaseMale {
  background-image: url(../../../images//wearables/MaleIcon.svg);
  background-size: 24px 24px;
}

.WearableDetail .Highlight .left > .BaseFemale {
  background-image: url(../../../images//wearables/FemaleIcon.svg);
  background-size: 24px 24px;
}

.WearableDetail .Highlight .left > .Unisex {
  background-image: url(../../../images//wearables/UnisexIcon.svg);
  background-size: 24px 24px;
}

.product-container {
  margin-top: 133px;
}

@media (max-width: 768px) {
  .product-container {
    margin-top: 20px;
    padding-left: 0;
  }
}

.price-dropdown {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.price-dropdown > .text {
  color: #676370 !important;
  font-size: 12px !important;
  font-family: Poppins;
  text-transform: none !important;
  height: 20px;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
}

.price-dropdown > .dropdown.icon {
  color: #676370 !important;
}

.price-dropdown > .text::before {
  content: url(../../../images/egg/calendar.svg);
  margin-right: 7px;
}

.next-breeding {
  width: 720px;
  margin-top: 20px;
}

.progress-last-bred-box {
  font-size: 15px;
  font-family: var(--font-family);
  color: rgb(255, 99, 132);
}

.progress-last-bred {
  font-size: 12px;
  font-family: var(--font-family);
  color: rgb(255, 99, 132);
}

.progress-can-breed-box {
  font-size: 15px;
  font-family: var(--font-family);
  color: rgb(54, 162, 235);
}

.progress-can-breed {
  font-size: 12px;
  font-family: var(--font-family);
  color: rgb(54, 162, 235);
}

.next-breeding-progress .bar .progress {
  top: 150% !important;
  right: -5em !important;
  color: rgb(217, 225, 160);
}

.next-breeding-progress .bar .progress-low {
  white-space: nowrap;
  position: absolute;
  width: auto;
  font-size: 0.92857143em;
  top: 150%;
  right: -7em;
  left: auto;
  bottom: auto;
  color: rgb(217, 225, 160);
  text-shadow: none;
  margin-top: -0.5em;
  font-weight: 700;
  text-align: left;
}

.in-game-status-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px;
  background: linear-gradient(0deg, #0f0f0f, #0f0f0f),
    linear-gradient(0deg, #2a2a2a, #2a2a2a);
  height: 59px;
  border-radius: 8px;
  border: 1px solid #2a2a2a;
}

.in-game-status-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.in-game-status-title {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}

.in-game-status-box {
  position: absolute;
  height: 16px;
  width: 82px;
  left: 109px;
  top: 50px;
  border-radius: 10px;
  background: #2a2a2a;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.in-game-status-box-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  /* line-height: 12px; */
  /* identical to box height, or 150% */

  text-transform: uppercase;

  /* Light */

  color: #ebebeb;
}

@media (max-width: 1201px) {
  .next-breeding {
    width: 660px;
  }
}

@media (max-width: 991px) {
  .next-breeding {
    width: 435px;
  }
}

@media (max-width: 768px) {
  .next-breeding {
    width: 100%;
  }

  .block-container {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .next-breeding {
    width: 358px;
  }
}

@media (max-width: 375px) {
  .next-breeding {
    width: 350px;
  }
}

@media (max-width: 320px) {
  .next-breeding {
    width: 290px;
  }
}

.kmon-detail-container {
  padding-bottom: 300px;
}

.position-relative {
  position: absolute;
  width: 100vw;
  left: 0;
}

.homepagedata .position-relative {
  position: relative;
  width: 100vw;
  left: 0;
}

.kmon-detail-bottom-bg {
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 400px;
  position: absolute;
}

.homepagedata .kmon-detail-bottom-bg {
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 400px;
  position: relative;
}

.kmon-detail-bottom-bg .kmon-detail-bottom-bg-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(../../../images/bottom-bg.jpg);
  background-size: cover;
  height: 100%;
  background-position: center;
}

.kmon-detail-bottom-wave-image {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-image: url(../../../images/Blue_waves.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  margin-bottom: -1%;
}
