.product-images-slider {
  .swiper-slide {
    padding-top: 100%;
    background-color: #2a2a2a;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .swiper-button-prev {
    left: 0;
    color: #000;
    // background-image: url('../../../images/kryptomon-fadeMount.png') !important;
  }

  .swiper-button-next {
    right: 0;
    color: #000;
  }
}

.product-images-slider-thumbs {
  .swiper-slide {
    cursor: pointer;
    background-color: #2a2a2a;
    border: 2px solid #2a2a2a;
    border-radius: 5px;
    margin-top: 5px;

    &-thumb-active {
      border-color: #f231af;
    }
  }

  .swiper-button-prev {
    left: 0;
    color: #000;
    // background-image: url('../../../images/kryptomon-fadeMount.png') !important;
  }

  .swiper-button-next {
    right: 0;
    color: #000;
  }

  &-wrapper {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
