.description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .description-container {
    width: 100%;
  }
}

.show-text {
  font-size: 14px;
  color: #676370;
  font-weight: 400;
  font-family: var(--font-monospace);
}

.hidden-text {
  max-height: 185px;
  overflow: hidden;
  position: relative;
}

.hidden-text-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #232123 9%, rgba(35, 33, 35, 0) 97%);
}

.more-button {
  align-self: center;
  background-color: #676370;
  border-radius: 10px;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #232123;
  padding: 5px 22px;
  border: none;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}
