.TextFilter .text-input input {
  font-size: 17px;
  line-height: 20px;
  background: rgb(50, 50, 50);
  color: white;
  font-weight: 600;
  font-family: var(--font-monospace);
  border: none;
  border-radius: 20px;
  padding-left: 45px;
  outline: none;
  width: 100%;
  height: 40px;
  background-image: url(../../../../images/search.svg);
  background-repeat: no-repeat;
  background-position-y: center !important;
  background-position-x: 18px;
}

.TextFilter .text-input input::placeholder {
  color: var(--secondary-text);
  font-weight: 400;
}

@media (max-width: 768px) {
  .TextFilter {
    margin-bottom: 4px;
  }

  .TextFilter .ui.sub.header.name {
    font-weight: normal;
  }
}

.search-results-area {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: rgb(46, 45, 45);
  margin-top: 10px;
  max-height: 330px;
  max-width: 530px;
  width: 100%;
  position: absolute;
  z-index: 100;
}
.search-results-items-area {
  overflow-y: scroll;
}

.dropdown-item {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgb(60, 59, 62);
}

.dropdown-item:hover {
  background: rgb(60, 60, 60);
  border-radius: 5px;
}

.dropdown-item-text {
  font-size: 18px;
  margin: auto 0;
  padding-top: 5px;
  font-family: var(--font-family);
}

.dropdown-item-image {
  width: 50px;
}

.dropdown-item-gen {
  background-color: #33d669;
  color: black;
  padding: 0 10px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 10px;
}

.dropdown-item-heart {
  width: 32px;
  height: 32px;
}

.search-result-showmore-btn {
  position: relative;
  width: 100%;
  background-color: black;
  border: none;
  height: 60px;
  box-shadow: 2px 2px 12px 2px #222a;
  cursor: pointer;
  color: rgb(240, 50, 175);
  font-size: 16px;
  font-weight: 700;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-result-showmore-btn:hover {
  text-shadow: 0 0 15px rgb(240, 50, 175);
}

.search-result-showmore-btn:active {
  transform: translateY(1px);
}