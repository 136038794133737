.SignInPage {
  display: flex;
  justify-content: center;
}

.SignInPage .youtube {
  pointer-events: all;
  margin-top: 20px;
  margin-bottom: 35px;
}

.SignInPage .SignIn.center {
  position: relative;
}

.dcl.login-modal .dcl.option.magic_auth .input {
  border: none;
}

.dcl.login-modal .dcl.option.magic_auth p.message {
  display: none;
}

.dcl.login-modal .dcl.option.magic_auth .dcl.field .overlay,
.ui.form .dcl.field .overlay {
  bottom: 12px;
}

.dcl.login-modal .title {
  margin: 0px;
}

.dcl.login-modal .dcl.option.magic_auth .ui.basic.button {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .dcl.login-modal {
    width: 400px !important;
  }

  .SignIn .ui.header {
    font-size: 16px !important;
  }

  .SignInPage .youtube {
    width: 90%;
  }
}