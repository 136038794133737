.ui.cards > .ui.card.link,
.ui.card.link.LootboxCard {
  box-shadow: var(--shadow-2);
  transform: translateY(-4px);
  cursor: pointer;
  border: var(--outline) !important;
}

.LootboxCard .card-image-text.candy {
  align-items: flex-end;
}

.LootboxCard .product-type-price {
  height: fit-content;
  background-color: #39c876;
  padding: 2px 10px;
  color: #000000;
  width: fit-content;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #000000;
}

.LootboxCard .product-type-price-candy {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 0px;
  height: fit-content;
  padding: 2px 10px;
  color: #e7e3d7;
  width: fit-content;
  border-radius: 10px;
  font-family: var(--font-inter-bold);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: right;
}

.LootboxCard .card-image {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.LootboxCard .product-info-number-card {
  margin: 0;
  font-family: var(--font-inter-bold);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #000000;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LootboxCard .product-description-container {
  height: 78px;
  min-height: 78px;
  justify-content: center;
}

.LootboxCard .product-description-container .product-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0px 0px;
}

.LootboxCard .product-description-left-item {
  font-family: var(--font-inter-regular);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 150% */

  color: #000000;
  text-transform: uppercase;
  width: 100%;
}

.LootboxCard .product-description-right-nft {
  /* background-color: #6331f2; */
  /* align-items: center; */
  /* justify-content: center;
  flex-direction: column;
  padding: 3px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 40px;
  height: 25px;
  border-radius: 13px;
  display: flex; */
}

.LootboxCard .product-description-right-nft-text {
  width: 15px;
  height: 12px;

  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 150% */

  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .LootboxCard .product-info-number-card {
    font-size: 13px;
  }

  .LootboxCard .product-type-price-candy {
    margin-top: -5px;
  }
}
