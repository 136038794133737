.NFTBrowse .Row .right.Column,
.NFTBrowse .ui.cards {
  position: relative;
}

.NFTBrowse .ui.cards {
  min-height: 150px;
}

.NFTBrowse .ui.cards+.empty {
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  color: var(--secondary-text);
}

.NFTBrowse>.ui.container {
  margin-top: 20px;
}

.NFTBrowse .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--background);
  opacity: 0.6;
}

.NFTBrowse .load-more {
  text-align: center;
  margin-top: 36px;
  height: 46px;
}

.NFTBrowse .Atlas {
  min-height: 700px;
  height: 100%;
  position: relative;
}

.NFTBrowse .Atlas canvas {
  border-radius: 8px;
}

.NFTBrowse .fullscreen-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(../../images/fullscreen.svg);
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 100%;
  cursor: pointer;
}

.NFTBrowse.fullscreen .Atlas canvas {
  border-radius: 0px;
}

.NFTBrowse.fullscreen .fullscreen-button {
  display: none;
}

.NFTBrowse .topbar {
  margin-bottom: 24px;
}

.NFTBrowse.fullscreen>.Row,
.NFTBrowse.fullscreen>.Row>.Column,
.NFTBrowse.fullscreen .Atlas {
  display: block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: auto;
  margin-top: 0px;
}

.NFTBrowse.fullscreen .blur-background {
  position: relative;
  background: rgba(22, 20, 26, 0.75);
  backdrop-filter: blur(20px);
  z-index: 1;
}

.NFTBrowse.fullscreen .NFTFilters {
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NFTBrowse.fullscreen .NFTFilters .topbar {
  margin-bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter {
  margin-bottom: 0px;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input {
  padding-bottom: 0px;
}

.NFTBrowse.fullscreen .NFTFilters .TextFilter input::placeholder {
  color: var(--text);
  opacity: 0.5;
}

@media (max-width: 768px) {
  .NFTBrowse .Layout .right.column>div {
    width: 100%;
  }

  .NFTBrowse .NFTSidebar ul.menu li {
    width: calc(100% - 32px);
  }

  .NFTBrowse .NFTFilters .topbar {
    align-items: center;
  }
}

.NFTBrowse.header {
  background-image: url(../../images/kryptomon-hero.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.NFTBrowse.header .dcl.tabs {
  font-family: var(--font-family);
  margin-top: 50px;
}

.browse-nft-list {
  margin-bottom: 400px;
}