.LootboxDetails.grid-container {
  display: grid;
  grid-template-areas:
    'type item item'
    'type item item';
  grid-gap: 8px;
}

.LootboxDetails.grid-container.details-container {
  justify-content: flex-start;
}
