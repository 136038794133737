.blueprint_container {
  width: 100%;
  padding: 26px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.blueprint_container .blueprint-buy-button {
  padding: 10px 32px;
  border-radius: 32px;
  background: linear-gradient(90deg, #F231AF 0%, #6331F2 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.blueprint_container .blueprint-buy-button .blueprint-buy-button-text {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.blueprint-seller-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.blueprint-seller-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.blueprint-seller-address {
  width: 440px;
  background-color: #101011;
  border-radius: 8px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #777777;
  padding: 5px 15px;
}

.blueprint-seller-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 70px;
  gap: 8px;
}

.blueprint-seller-title {
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #EBEBEB;
}

.blueprint-price {
  flex: 1;
  background-color: #101011;
  border-radius: 8px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #EBEBEB;
  padding: 5px 15px;
}

.blueprint-border-bottom {
  border-bottom: 1px solid #444444;
}

.blueprint_container button.ui.button {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .blueprint-container {
    width: 100%;
    padding: 0px 10px;
  }

  .blueprint-seller-address {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}